import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_BulkAddAccessories = _resolveComponent("BulkAddAccessories")
  const _component_BulkAddRemoveBadges = _resolveComponent("BulkAddRemoveBadges")
  const _component_BulkAddRemoveDiscounts = _resolveComponent("BulkAddRemoveDiscounts")
  const _component_BulkAddWeclappDocuments = _resolveComponent("BulkAddWeclappDocuments")
  const _component_BulkAddShopwareDocuments = _resolveComponent("BulkAddShopwareDocuments")
  const _component_BulkSetShopOnlineStatus = _resolveComponent("BulkSetShopOnlineStatus")
  const _component_BulkEditPromotions = _resolveComponent("BulkEditPromotions")
  const _component_BulkEditMetatags = _resolveComponent("BulkEditMetatags")
  const _component_BulkEditCompliance = _resolveComponent("BulkEditCompliance")
  const _component_BulkEditTags = _resolveComponent("BulkEditTags")
  const _component_BulkEditCustomFields = _resolveComponent("BulkEditCustomFields")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("i", {
      class: "pi pi-cog text-green-600",
      style: {"padding-left":"7px","cursor":"pointer"},
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.bulkMenuToggle && _ctx.bulkMenuToggle(...args))),
      "aria-haspopup": "true",
      "aria-controls": "overlay_tmenu"
    }),
    _createVNode(_component_TieredMenu, {
      ref: "bulkMenu",
      id: "overlay_tmenu",
      model: _ctx.bulkOptions,
      popup: ""
    }, null, 8, ["model"]),
    _createVNode(_component_BulkAddAccessories, {
      products: _ctx.mergedSelection,
      displayResponsive: _ctx.dialogs.find(item => item.name === 'Accessories').visible,
      mainProductsSelected: _ctx.dialogs.find(item => item.name === 'Accessories').mainProductSelected,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "displayResponsive", "mainProductsSelected", "onCloseDialog"]),
    _createVNode(_component_BulkAddRemoveBadges, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Badges').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Badges').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Badges').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddRemoveDiscounts, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Discounts').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Discounts').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Discounts').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddWeclappDocuments, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'WeclappDocuments').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'WeclappDocuments').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddShopwareDocuments, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'ShopwareDocuments').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'ShopwareDocuments').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkSetShopOnlineStatus, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'OnlineStatus').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'OnlineStatus').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'OnlineStatus').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditPromotions, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Promotions').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Promotions').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditMetatags, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Metatags').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Metatags').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Metatags').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditCompliance, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Compliance').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Compliance').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditTags, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Tags').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Tags').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Tags').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditCustomFields, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'CustomFields').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'CustomFields').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"])
  ], 64))
}