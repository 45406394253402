import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4733d2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "text-gray-600"
}

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-datatable-sm text-sm",
    stripedRows: "",
    paginator: "",
    scrollable: "",
    scrollHeight: "55vh",
    rows: 100,
    value: _ctx.badges,
    filters: _ctx.filters,
    "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
    filterDisplay: "row",
    responsiveLayout: "scroll",
    "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    "rows-per-page-options": [10,20,50,100,200],
    "current-page-report-template": "{first} to {last} of {totalRecords}"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, { headerStyle: "width: 3rem" }, {
        body: _withCtx(({data}) => [
          (data.active)
            ? (_openBlock(), _createBlock(_component_p_checkbox, {
                key: 0,
                value: data,
                modelValue: _ctx.selectedBadges,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedBadges) = $event))
              }, null, 8, ["value", "modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "name",
        header: _ctx.$t('labels.name'),
        style: {"min-width":"12rem"}
      }, {
        filter: _withCtx(({filterModel,filterCallback}) => [
          _createVNode(_component_InputText, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
            class: "p-column-filter text-sm",
            placeholder: _ctx.$t('labels.name')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
        ]),
        body: _withCtx(({data}) => [
          (data.active)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(data.name), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.name), 1))
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "filters"]))
}