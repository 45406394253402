import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import {computed, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import LoadingPlugin from 'vue-loading-overlay';
import {getSingle, update} from '@/services/shipments';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {useVuelidate} from '@vuelidate/core';
import {required, helpers} from '@vuelidate/validators';
import PackageDimensionsForm from '@/components/shipment/package-dimensions/package-dimensions-form.vue';
import ExternalCarrierForm from '@/components/shipment/external-carrier-data/external-carrier-form.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import {getAll} from '@/services/metadata';
import store from '@/store';
import {i18n} from '@/utils/i18n';
import Message from 'primevue/message';
import {
    euStates,
    upsDangerousGoodsGroundTransportStates,
    dhlStates
} from '@/utils/helpers';
import {useConfirm} from 'primevue/useconfirm';
import {getAll as getAllShipments} from '@/services/shipments';
import {FilterMatchMode} from 'primevue/api';
import Panel from 'primevue/panel';
import OverlayPanel from 'primevue/overlaypanel';
import Fieldset from 'primevue/fieldset';
import eventBus from '@/event-bus/event-bus';

export default {
    components: {
        InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        'p-multiselect': MultiSelect,
        'p-checkbox': Checkbox,
        'p-fieldset': Fieldset,
        Panel,
        OverlayPanel,
        PackageDimensionsForm,
        ExternalCarrierForm,
        Message
    },
    setup() {
        const route = useRoute();
        const loading = ref(false);
        const toast = useToast();
        const confirm = useConfirm();
        const isPaste = ref(false);
        const shipmentNumber = ref(null);
        const shipmentDetails = ref(null);
        const shippingCarrierOptions = ref([]);
        const shippingLocationOptions = ref([]);
        const workflowExecutionResults = ref([]);
        const showWorkflowExecutionOverviewDialog = ref(false);
        const workflowIdentifier = ref(null);

        const shipmentNumberInputField = ref(null);

        const displayDangerousGoodsConfirmationDialog = ref(false);

        const dt = ref();
        const expandedRows = ref([]);
        const groupedShipmentOptions = ref([]);

        const opShippingLocations = ref(null);

        const toggleDataTable = (event: any) => {
            if (shippingLocationOptions.value.length > 0) {
                opShippingLocations.value.toggle(event);
            }
        };

        onMounted(() => {
            if (route.params?.shipmentNumber) {
                shipmentNumber.value = route.params.shipmentNumber;
                loadShipmentDetails();
            } else {
                resetShipmentNumber();
            }

            getAll(['shippingCarrier'])
                .then((data) => {
                    if (data.data?.shippingCarrier) {
                        shippingCarrierOptions.value = data.data.shippingCarrier
                            .filter((item: {active: boolean}) => item.active)
                            .map((item: {id: string; name: string}) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                };
                            });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        watch(route, (args) => {
            if (!args.params?.shipmentNumber) {
                resetShipmentNumber();
            }
        });

        watch(shipmentDetails, () => {
            if (
                shipmentDetails.value &&
                (shipmentDetails.value.recipientParty ||
                    shipmentDetails.value.destinationWarehouse) &&
                shipmentDetails.value.warehouse?.name
            ) {
                getAllShipments({
                    first: 0,
                    rows: 50,
                    sortField: 'createdDate',
                    sortOrder: -1,
                    columns: ['shipmentItems', 'isCommissioningComplete'],
                    filters: Object.assign(
                        {
                            status: {
                                value: ['NEW', 'DELIVERY_NOTE_PRINTED'],
                                matchMode: FilterMatchMode.IN
                            },
                            warehouse: {
                                value: shipmentDetails.value.warehouse.name,
                                matchMode: FilterMatchMode.EQUALS
                            }
                        },
                        shipmentDetails.value.recipientParty
                            ? {
                                  recipientPartyId: {
                                      value: shipmentDetails.value
                                          .recipientParty.id,
                                      matchMode: FilterMatchMode.EQUALS
                                  }
                              }
                            : {
                                  destinationWarehouseId: {
                                      value: shipmentDetails.value
                                          .destinationWarehouse.id,
                                      matchMode: FilterMatchMode.EQUALS
                                  }
                              }
                    )
                })
                    .then((data) => {
                        groupedShipmentOptions.value = data.data.items.filter(
                            (item: {shipmentNumber: string}) =>
                                item.shipmentNumber !==
                                shipmentDetails.value.shipmentNumber
                        );

                        groupedShipmentOptions.value.forEach((grop: any) => {
                            if (
                                !groupedMultiSelectOptionFilterFunction(grop) &&
                                (
                                    shipmentDetails.value?.customAttributes
                                        ?.shipment_grouped_shipments || []
                                ).some((cust: any) => {
                                    return (
                                        cust.entityName === 'shipment' &&
                                        cust.entityId === grop.id
                                    );
                                })
                            ) {
                                state.value.groupedShipments.push(
                                    grop.shipmentNumber
                                );

                                onCheckboxChange();
                            }
                        });
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        });

        const resetShipmentNumber = () => {
            shipmentNumber.value = null;
            shipmentDetails.value = null;
            const inputField: HTMLElement = shipmentNumberInputField.value
                ?.$el as HTMLElement;

            if (inputField) {
                setTimeout(() => {
                    inputField.focus();
                }, 200);
            }
            if (state.value) {
                Object.assign(state.value, {
                    shippingCarrier: null,
                    shippingLocation: '1',
                    groupedShipments: [],
                    packageItems: [],
                    externalCarrierData: null
                });
            }
        };

        const state = ref({
            shippingCarrier: null,
            shippingLocation: '1',
            groupedShipments: [],
            packageItems: [],
            externalCarrierData: null
        });

        const groupedShipmentItems = computed(() => {
            const groupedShipments = [shipmentDetails.value].concat(
                (groupedShipmentOptions.value || []).filter((item: any) => {
                    return (
                        (state.value.groupedShipments || []).findIndex(
                            (gr: any) => gr === item.shipmentNumber
                        ) !== -1
                    );
                })
            );

            let result: Array<any> = [];
            let counter = 0;

            groupedShipments.forEach((groupedShipment: any) => {
                (groupedShipment?.shipmentItems || []).forEach(
                    (shipmentItem: any) => {
                        shipmentItem.shipmentNumber =
                            groupedShipment.shipmentNumber;
                        shipmentItem.positionNumber = (++counter).toString();
                        result = result.concat(shipmentItem);
                    }
                );
            });

            return result;
        });

        const showUpsDangerousGoodsButton = computed(() => {
            return (
                isUpsCarrier.value &&
                isWarehouseSuitableForSendingDangerousGoods.value &&
                groupedShipmentItems.value.some((item): any => {
                    return item.article_flag_akku_groesser_100wh;
                })
            );
        });

        const isWarehouseSuitableForSendingDangerousGoods = computed(() => {
            return [
                'Wiesbaden',
                'Hamburg',
                'Berlin',
                'Hamburg Obenhaupt',
                'Hamburg Re-Commerce & Service Center',
                'VDH'
            ].includes(shipmentDetails.value?.warehouse?.name);
        });

        const isCustomerBlocked = computed(() => {
            return (
                shipmentDetails.value?.recipientParty &&
                (shipmentDetails.value?.recipientParty.customerBlocked ||
                    shipmentDetails.value?.recipientParty.customerDeliveryBlock)
            );
        });

        const haveCompleteWorkflowButtonDisabled = computed(() => {
            if (savingInProgress.value) {
                return i18n.global.t('labels.inProgress');
            }

            if (isCustomerBlocked.value) {
                return i18n.global.t('messages.deliveryToBlockedCustomer');
            }

            if (
                thirdCountryRecipientAddress.value &&
                shipmentNetAmount.value &&
                shipmentNetAmount.value >= 1000
            ) {
                return i18n.global.t(
                    'messages.deliveryUsingCompleteWorkflowForThirdCountries'
                );
            }

            if (
                !isWarehouseSuitableForSendingDangerousGoods.value &&
                groupedShipmentItems.value.some((item): any => {
                    return item.article_flag_akku_groesser_100wh;
                })
            ) {
                return i18n.global.t(
                    'messages.deliveryFromUnsuitableWarehouse'
                );
            }

            return null;
        });

        const isStatusNew = computed(() => {
            return shipmentDetails.value?.status === 'NEW';
        });

        const haveSavingButtonDisabled = computed(() => {
            if (savingInProgress.value) {
                return i18n.global.t('labels.inProgress');
            }

            if (isCustomerBlocked.value) {
                return i18n.global.t('messages.deliveryToBlockedCustomer');
            }

            if (isStatusNew.value) {
                return null;
            }

            if (
                groupedShipmentItems.value.some((item): any => {
                    return item.article_flag_akku_groesser_100wh;
                })
            ) {
                if (isDhlCarrier.value) {
                    return i18n.global.t(
                        'messages.deliveryUsingUnsuitableShippingCarrier'
                    );
                }

                if (!isWarehouseSuitableForSendingDangerousGoods.value) {
                    return i18n.global.t(
                        'messages.deliveryFromUnsuitableWarehouse'
                    );
                }
            }

            if (!shipmentDetails.value?.isCommissioningComplete) {
                return i18n.global.t(
                    'messages.deliveryContainsUnconfirmedWithdrawals'
                );
            }

            if (!shipmentDetails.value?.picksComplete) {
                return i18n.global.t(
                    'messages.deliveryContainsIncompleteWithdrawals'
                );
            }

            if (
                showUpsDangerousGoodsButton.value &&
                !shipmentDetails.value?.isPickup &&
                ('DE' ===
                    shipmentDetails.value?.recipientAddress?.countryCode ||
                    !upsDangerousGoodsSuitableRecipientAddress.value)
            ) {
                return i18n.global.t(
                    'messages.deliveryOfDangerousGoodsToNotSuitableCountry'
                );
            }

            if (
                isDhlCarrier.value &&
                !shipmentDetails.value?.isPickup &&
                dhlStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) === -1
            ) {
                return i18n.global.t(
                    'messages.deliveryUsingDHLToNotSuitableCountry'
                );
            }

            return null;
        });

        const rules = {
            shippingCarrier: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    (value: any) => {
                        return value || shipmentDetails.value?.isPickup;
                    }
                )
            },
            shippingLocation: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            groupedShipments: {},
            packageItems: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    (value: any) => {
                        return (
                            !showPackageDimensionForm.value ||
                            (value || []).length > 0
                        );
                    }
                ),
                dangerousGoods: helpers.withMessage(
                    i18n.global.t(
                        'messages.atLeastOnePackageMustBeMarkedAsDangerousGoods'
                    ),
                    (value: any) => {
                        return (
                            !showUpsDangerousGoodsButton.value ||
                            (value || []).some(
                                (item: any) => item.dangerousGoods
                            )
                        );
                    }
                )
            },
            externalCarrierData: {}
        };

        const v$ = useVuelidate(rules, state);
        const submitted = ref(false);
        const savingInProgress = ref(false);

        const onCheckboxChange = () => {
            eventBus.emit(
                'group-shipment-toggled',
                groupedShipmentOptions.value.filter((item: any) =>
                    state.value.groupedShipments.some(
                        (gr: any) => gr === item.shipmentNumber
                    )
                )
            );
        };

        const confirmationNeededForNotSelectedGroupedShipments = computed(
            () => {
                return groupedShipmentOptions.value.some(
                    (grop: any) =>
                        !groupedMultiSelectOptionFilterFunction(grop) &&
                        (
                            shipmentDetails.value?.customAttributes
                                ?.shipment_grouped_shipments || []
                        ).some(
                            (cust: any) =>
                                cust.entityName === 'shipment' &&
                                cust.entityId === grop.id
                        ) &&
                        !state.value.groupedShipments.some(
                            (st: any) => st === grop.shipmentNumber
                        )
                );
            }
        );

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            if (
                (confirmationNeededForThirdCountryAndOrderMoreThanOrEqual1000.value &&
                    shipmentDetails.value?.status ===
                        'DELIVERY_NOTE_PRINTED') ||
                confirmationNeededForThirdCountryAndSpecificShipmentType.value ||
                confirmationNeededForMissingUPSAccountNumber.value ||
                confirmationNeededForMissingMSDSDocument.value ||
                confirmationNeededForNotSelectedGroupedShipments.value
            ) {
                let message = 'messages.shipmentMaskMissingCustomDocuments';

                if (confirmationNeededForNotSelectedGroupedShipments.value) {
                    message =
                        'messages.shipmentMaskNotIncludedCombinedShipments';
                }

                if (confirmationNeededForMissingUPSAccountNumber.value) {
                    message = 'messages.shipmentMaskMissingUpsAccountNumber';
                }

                if (confirmationNeededForMissingMSDSDocument.value) {
                    message =
                        'messages.shipmentMaskMissingDangerousGoodsMSDSDocument';
                }

                confirm.require({
                    message: i18n.global.t(message),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t(
                        'buttons.completeShipmentNevertheless'
                    ),
                    rejectLabel: i18n.global.t('buttons.cancel'),
                    acceptClass: 'btn-success',
                    rejectClass: 'btn-danger',
                    defaultFocus: 'reject',
                    accept: () => {
                        savingInProgress.value = true;
                        loading.value = true;
                        workflowIdentifier.value = 'full';

                        update(shipmentNumber.value, {
                            workflow: workflowIdentifier.value,
                            ...state.value
                        })
                            .then((data: any) => {
                                workflowExecutionResults.value = data.data;
                                if (
                                    workflowExecutionResults.value.some(
                                        (item: any) => item.error
                                    )
                                ) {
                                    showWorkflowExecutionOverviewDialog.value =
                                        true;
                                } else {
                                    handleWorkflowResultsConfirmed();
                                }
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                            })
                            .finally(() => {
                                savingInProgress.value = false;
                                loading.value = false;
                            });
                    }
                });
            } else {
                savingInProgress.value = true;
                loading.value = true;
                workflowIdentifier.value = 'full';
                update(shipmentNumber.value, {
                    workflow: workflowIdentifier.value,
                    ...state.value
                })
                    .then((data: any) => {
                        workflowExecutionResults.value = data.data;
                        if (
                            workflowExecutionResults.value.some(
                                (item: any) => item.error
                            )
                        ) {
                            showWorkflowExecutionOverviewDialog.value = true;
                        } else {
                            handleWorkflowResultsConfirmed();
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                        loading.value = false;
                    });
            }
        };

        const handleStatusUpdate = (workflowId: string) => {
            savingInProgress.value = true;
            loading.value = true;
            workflowIdentifier.value = workflowId;

            update(shipmentNumber.value, {
                workflow: workflowIdentifier.value,
                ...state.value
            })
                .then((data: any) => {
                    workflowExecutionResults.value = data.data;
                    if (
                        workflowExecutionResults.value.some(
                            (item: any) => item.error
                        )
                    ) {
                        showWorkflowExecutionOverviewDialog.value = true;
                    } else {
                        handleWorkflowResultsConfirmed();
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                    loading.value = false;
                });
        };

        const loadShipmentDetails = async () => {
            loading.value = true;
            state.value.shippingLocation = await store.dispatch(
                'shipment/getLocationForPlatform',
                store.getters['auth/platform']
            );
            getSingle(shipmentNumber.value)
                .then((data) => {
                    shipmentDetails.value = data.data;
                    if (
                        data.data?.shipmentLocationConfiguration
                            ?.locationOptions
                    ) {
                        shippingLocationOptions.value = Object.keys(
                            data.data.shipmentLocationConfiguration
                                .locationOptions
                        ).map((index: any) => {
                            return {
                                label:
                                    i18n.global.t(
                                        'labels.shipment.shipmentLocation'
                                    ) +
                                    ' ' +
                                    index,
                                value: index
                            };
                        });
                    }

                    if (
                        !isStatusNew.value &&
                        !shipmentDetails.value?.isCommissioningComplete
                    ) {
                        toast.warning(
                            i18n.global.t(
                                'messages.deliveryContainsUnconfirmedWithdrawals'
                            )
                        );
                    }

                    if (
                        !isStatusNew.value &&
                        !shipmentDetails.value?.picksComplete
                    ) {
                        toast.warning(
                            i18n.global.t(
                                'messages.deliveryContainsIncompleteWithdrawals'
                            )
                        );
                    }

                    if (isCustomerBlocked.value) {
                        toast.warning(
                            i18n.global.t('messages.deliveryToBlockedCustomer')
                        );
                    }

                    Object.assign(state.value, {
                        shippingCarrier: data.data.shippingCarrier?.id,
                        groupedShipments: []
                    });
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(i18n.global.t('messages.noSuchShipment'));
                    } else {
                        toast.error(error.message);
                    }

                    resetShipmentNumber();
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const onShipmentNumberChange = () => {
            if (!isPaste.value) {
                return;
            }
            loadShipmentDetails();
            isPaste.value = false;
        };

        const onShipmentNumberPaste = () => {
            isPaste.value = true;
        };

        const onShipmentNumberEnter = () => {
            loadShipmentDetails();
        };

        const onShippingCarrierChange = (event: any) => {
            Object.assign(state.value, {
                shippingCarrier: event?.value
            });
            if (!isUpsCarrier.value && !isDhlCarrier.value) {
                state.value.groupedShipments = [];
            }
        };

        const changeShippingLocation = (shippingLocation: string) => {
            const platform = store.getters['auth/platform'];
            store.dispatch('shipment/setShippingLocation', {
                platform,
                shippingLocation
            });
            Object.assign(state.value, {
                shippingLocation
            });
        };

        const rowClass = (data: any) => {
            return data.hasChildren ? 'bg-bluegray-200' : null;
        };

        const shipmentNumberPreSelected = computed(() => {
            return !!route.params?.shipmentNumber;
        });

        const confirmationNeededForAkkuGroesser = computed(() => {
            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {article_flag_akku_groesser_100wh: boolean}) =>
                    shipmentItem.article_flag_akku_groesser_100wh
            );
        });

        const confirmationNeededForAkkuKleiner = computed(() => {
            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {article_flag_akku_kleiner_100wh: boolean}) =>
                    shipmentItem.article_flag_akku_kleiner_100wh
            );
        });

        const confirmationNeededForAerosols = computed(() => {
            return (shipmentDetails.value?.shipmentItems || []).some(
                (shipmentItem: {article_flag_aerosole: boolean}) =>
                    shipmentItem.article_flag_aerosole
            );
        });

        const confirmationNeededForSpedition = computed(() => {
            if (isSpeditionCarrier.value) {
                return null;
            }

            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {
                    article_spedition: boolean;
                    article_sperrgut: boolean;
                }) =>
                    shipmentItem.article_spedition ||
                    shipmentItem.article_sperrgut
            )?.article?.name;
        });

        const thirdCountryRecipientAddress = computed(() => {
            return (
                shipmentDetails.value?.recipientAddress?.countryCode &&
                euStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) === -1
            );
        });

        const upsDangerousGoodsSuitableRecipientAddress = computed(() => {
            return (
                shipmentDetails.value?.recipientAddress?.countryCode &&
                upsDangerousGoodsGroundTransportStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) !== -1
            );
        });

        const customsDocuments = computed(() => {
            const isOfSpecificType =
                !shipmentDetails.value?.salesOrder ||
                [
                    'CONSIGNMENT',
                    'CUSTOMER_COMPENSATION',
                    'SUPPLIER_RETURN'
                ].indexOf(shipmentDetails.value?.shipmentType) !== -1;

            return isOfSpecificType
                ? shipmentDetails.value?.documents
                : shipmentDetails.value?.salesInvoice?.documents;
        });

        const isUpsCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.upsCarriers?.indexOf(shippingCarrier) !==
                    -1
            );
        });

        const isSpeditionCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.speditionCarriers
                    ?.map((item: number) => item.toString())
                    .indexOf(shippingCarrier) !== -1
            );
        });

        const isDhlCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.dhlCarriers?.indexOf(shippingCarrier) !==
                    -1
            );
        });

        const shipmentNetAmount = computed(() => {
            let sum: number | null = null;
            groupedShipmentItems.value.forEach((item): any => {
                const salesOrderItem =
                    shipmentDetails.value?.salesOrder?.orderItems.find(
                        (orderItem: any) =>
                            orderItem.id === item.salesOrderItemId
                    );

                if (salesOrderItem) {
                    sum =
                        (sum || 0.0) +
                        parseFloat(salesOrderItem.netAmountInCompanyCurrency);
                }
            });
            return sum || shipmentDetails.value?.salesOrder?.netAmount;
        });

        const confirmationNeededForThirdCountryAndOrderMoreThanOrEqual1000 =
            computed(() => {
                const generalConditions =
                    isUpsCarrier.value &&
                    thirdCountryRecipientAddress.value &&
                    shipmentNetAmount.value &&
                    shipmentNetAmount.value >= 1000;

                if (!generalConditions) {
                    return false;
                }

                if (!customsDocuments.value) {
                    return true;
                }

                let allFound = false;

                ['R-', 'I-', 'SR-', 'FI-'].forEach((item: string) => {
                    allFound =
                        allFound ||
                        customsDocuments.value.some(
                            (document: {name: string}) =>
                                document.name.startsWith(item)
                        );
                });

                allFound =
                    allFound &&
                    customsDocuments.value.some((document: {name: string}) =>
                        document.name.startsWith('ABD_')
                    );

                return !allFound;
            });

        const confirmationNeededForThirdCountryAndSpecificShipmentType =
            computed(() => {
                const generalConditions =
                    isUpsCarrier.value &&
                    thirdCountryRecipientAddress.value &&
                    [
                        'CONSIGNMENT',
                        'CUSTOMER_COMPENSATION',
                        'SUPPLIER_RETURN'
                    ].indexOf(shipmentDetails.value?.shipmentType) !== -1;

                if (!generalConditions) {
                    return false;
                }

                if (!customsDocuments.value) {
                    return true;
                }

                let allFound = false;

                ['R-', 'I-', 'SR-', 'FI-'].forEach((item: string) => {
                    allFound =
                        allFound ||
                        customsDocuments.value.some(
                            (document: {name: string}) =>
                                document.name.startsWith(item)
                        );
                });

                return !allFound;
            });

        const confirmationNeededForMissingUPSAccountNumber = computed(() => {
            return (
                (shipmentDetails.value?.customAttributes
                    ?.cust_ups_account_bill ||
                    null) &&
                !(
                    shipmentDetails.value?.customAttributes
                        ?.cust_ups_account_no || null
                )
            );
        });

        const confirmationNeededForMissingMSDSDocument = computed(() => {
            return groupedShipmentItems.value.some((item): any => {
                item.article_flag_akku_groesser_100wh &&
                    !item.article.hasMSDSDocument;
            });
        });

        const outputRecipient = (data: any) => {
            let output = '';
            if (data.recipientCustomerNumber && data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )}/${i18n.global.t('labels.shipment.recipientSupplier')} [${
                    data.recipientCustomerNumber
                }/${data.recipientSupplierNumber}]`;
            } else if (data.recipientCustomerNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )} [${data.recipientCustomerNumber}]`;
            } else if (data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientSupplier'
                )} [${data.recipientSupplierNumber}]`;
            } else {
                return '';
            }

            if (data.recipientParty?.company) {
                output += ` ${data.recipientParty.company}`;
            } else if (
                data.recipientParty?.lastName &&
                data.recipientParty?.firstName
            ) {
                output += ` ${data.recipientParty.lastName}, ${data.recipientParty.firstName}`;
            }

            return output;
        };

        const handleFinalSubmitClick = async () => {
            const isFormCorrect = await v$.value.$validate();
            submitted.value = true;

            if (!isFormCorrect) {
                return;
            }

            if (
                confirmationNeededForAkkuGroesser.value ||
                confirmationNeededForAkkuKleiner.value ||
                confirmationNeededForAerosols.value ||
                confirmationNeededForSpedition.value
            ) {
                displayDangerousGoodsConfirmationDialog.value = true;
            } else {
                handleDangerousGoodsConfirmClick();
            }
        };

        const handleDangerousGoodsConfirmClick = () => {
            displayDangerousGoodsConfirmationDialog.value = false;
            handleSubmit(!v$.value.$invalid);
        };

        const handleCancelClick = () => {
            if (route.params?.shipmentNumber) {
                router.push({
                    name: 'ShipmentMask'
                });
            } else {
                resetShipmentNumber();
            }
        };

        const shallowEqual = (object1: any, object2: any): boolean => {
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                if (object1[key] !== object2[key]) {
                    return false;
                }
            }

            return true;
        };

        const resolveSalesChannel = (shipment: any) => {
            return (
                shipment?.salesOrder?.salesChannel ||
                shipment?.recipientParty?.customerSalesChannel
            );
        };

        const handleWorkflowResultsConfirmed = () => {
            showWorkflowExecutionOverviewDialog.value = false;
            if (workflowIdentifier.value === 'create-delivery-note') {
                workflowExecutionResults.value.length = 0;
                handleCancelClick();
            } else if (workflowIdentifier.value === 'full') {
                if (
                    workflowExecutionResults.value.some(
                        (item: any) => item.error
                    )
                ) {
                    workflowExecutionResults.value.length = 0;
                } else {
                    workflowExecutionResults.value.length = 0;
                    if (route.params?.shipmentNumber) {
                        router.push({
                            name: 'Shipments'
                        });
                    } else {
                        resetShipmentNumber();
                    }
                }
            }
        };

        const showPackageDimensionForm = computed(() => {
            return (
                !isStatusNew.value &&
                !shipmentDetails.value?.isPickup &&
                (isDhlCarrier.value || isUpsCarrier.value)
            );
        });

        const groupedMultiSelectOptionFilterFunction = (item: any) =>
            !item.isCommissioningComplete ||
            !item.picksComplete ||
            !shallowEqual(
                item.recipientAddress || {},
                shipmentDetails.value?.recipientAddress || {}
            ) ||
            item.shippingCarrier?.id !==
                shipmentDetails.value?.shippingCarrier?.id ||
            item.status !== shipmentDetails.value?.status ||
            resolveSalesChannel(item) !==
                resolveSalesChannel(shipmentDetails.value);

        return {
            shipmentNumber,
            shipmentNumberPreSelected,
            loading,
            shipmentDetails,
            shippingLocationOptions,
            shippingCarrierOptions,
            onShipmentNumberChange,
            onShipmentNumberEnter,
            onShipmentNumberPaste,
            changeShippingLocation,
            outputRecipient,
            onShippingCarrierChange,
            rowClass,
            dt,
            expandedRows,
            state,
            v$,
            submitted,
            savingInProgress,
            displayDangerousGoodsConfirmationDialog,
            confirmationNeededForAkkuGroesser,
            confirmationNeededForAkkuKleiner,
            confirmationNeededForAerosols,
            shipmentNumberInputField,
            handleCancelClick,
            handleDangerousGoodsConfirmClick,
            groupedShipmentOptions,
            groupedMultiSelectOptionFilterFunction,
            groupedShipmentItems,
            isUpsCarrier,
            isDhlCarrier,
            onCheckboxChange,
            showUpsDangerousGoodsButton,
            haveSavingButtonDisabled,
            haveCompleteWorkflowButtonDisabled,
            confirmationNeededForSpedition,
            opShippingLocations,
            toggleDataTable,
            isStatusNew,
            handleStatusUpdate,
            onPackageDimensionsEntered: (data: any) => {
                state.value.packageItems = [...(data || [])];
            },
            onExternalCarrierDataEntered: (data: any) => {
                state.value.externalCarrierData = {...data};
            },
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            getShipmentTotalGrossWeight: (shipment: any) => {
                return (shipment.shipmentItems || []).reduce(
                    (accumulator: any, currentValue: any) =>
                        accumulator +
                            parseFloat(
                                currentValue.article?.articleGrossWeight ||
                                    '0.0'
                            ) *
                                currentValue.quantity || 1,
                    0
                );
            },
            handleWeightSubmit: () => {
                eventBus.emit('trigger-shipment-packages-form-submit');
                eventBus.emit('trigger-external-carrier-form-submit');
                setTimeout(() => {
                    handleFinalSubmitClick();
                }, 100);
            },
            showWorkflowExecutionOverviewDialog,
            workflowExecutionResults,
            workflowIdentifier,
            handleWorkflowResultsConfirmed,
            showPackageDimensionForm,
            useArticleDimensions: (data: any) => {
                eventBus.emit(
                    'trigger-adding-package-from-product-dimensions',
                    {
                        grossWeight:
                            +data.article.articleGrossWeight.toFixed(2),
                        length: +(data.article.articleLength * 100).toFixed(1),
                        height: +(data.article.articleHeight * 100).toFixed(1),
                        width: +(data.article.articleWidth * 100).toFixed(1),
                        singlePackageArticleNumber: data.article.articleNumber
                    }
                );
            }
        };
    }
};
