<loading v-model:active="loading" />
<Panel
    :header="$t('labels.manufacturers') + ' - ' + $t('labels.responsiblePersons')"
>
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="manufacturers"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        editMode="row"
        v-model:editingRows="editingRows"
        @row-edit-save="onRowEditComplete"
    >
        <Column field="id" sortable style="min-width: 12rem" header="Marke">
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="'Marke'"
                />
            </template>
        </Column>
        <Column
            field="name"
            sortable
            style="min-width: 16rem"
            :header="$t('labels.manufacturerName')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.manufacturerName')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-12rem"
                />
            </template>
        </Column>
        <Column
            field="address"
            sortable
            style="min-width: 16rem"
            :header="$t('labels.address')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.address')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-12rem"
                />
            </template>
        </Column>
        <Column
            field="city"
            sortable
            style="min-width: 12rem"
            :header="$t('labels.city')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.city')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-10rem"
                />
            </template>
        </Column>
        <Column
            field="zipCode"
            sortable
            style="min-width: 10rem"
            :header="$t('labels.zipCode')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.zipCode')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-6rem"
                />
            </template>
        </Column>
        <Column
            field="country"
            sortable
            style="min-width: 10rem"
            :header="$t('labels.country')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.country')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-6rem"
                />
            </template>
        </Column>
        <Column
            field="email"
            sortable
            style="max-width: fit-content"
            :header="$t('labels.email')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.email')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-12rem"
                />
            </template>
        </Column>
        <Column
            field="website"
            sortable
            style="max-width: fit-content"
            :header="$t('labels.website')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.website')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-12rem"
                />
            </template>
        </Column>
        <Column
            field="phone"
            sortable
            style="max-width: fit-content"
            :header="$t('labels.phoneNumber')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.phoneNumber')"
                />
            </template>
            <template #editor="{ data, field }">
                <InputText
                    v-model="data[field]"
                    class="p-column-filter text-sm w-10rem"
                />
            </template>
        </Column>
        <Column
            field="lastModifiedAtTimestamp"
            sortable
            style="max-width: fit-content"
            :header="$t('labels.productHome.lastUpdate')"
        >
            <template #body="{data}"> {{data.lastModifiedAt}} </template>
        </Column>
        <Column
            frozen
            alignFrozen="right"
            v-if="editPermissionAvailable"
            rowEditor
            style="min-width: 8rem"
            bodyStyle="text-align:center"
        ></Column>
    </DataTable>
</Panel>
