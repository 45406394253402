import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (
    parentId: string | null = null,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const params = {};

    if (parentId) {
        Object.assign(params, {parentId});
    }

    if (platform) {
        Object.assign(params, {platform});
    }

    return apiClient.get('/api/v1/products/categories', {
        params
    });
};

export const getSubTree = (
    nodeId: string,
    platform: string | null = null,
    withParentNode: boolean = true
): Promise<AxiosResponse> => {
    const params = {
        parentId: nodeId,
        childCategories: true,
        withParentNode
    };

    if (platform) {
        Object.assign(params, {platform});
    }

    return apiClient.get('/api/v1/products/categories', {
        params
    });
};

export const search = (
    searchTerm: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/products/categories', {
        params: platform
            ? {
                  searchTerm,
                  platform
              }
            : {
                  searchTerm
              }
    });
};
