<i
    class="pi pi-cog text-green-600"
    style="padding-left: 7px; cursor: pointer"
    @click="bulkMenuToggle"
    aria-haspopup="true"
    aria-controls="overlay_tmenu"
></i>
<TieredMenu ref="bulkMenu" id="overlay_tmenu" :model="bulkOptions" popup />
<BulkAddAccessories
    :products="mergedSelection"
    :displayResponsive="dialogs.find(item => item.name === 'Accessories').visible"
    :mainProductsSelected="dialogs.find(item => item.name === 'Accessories').mainProductSelected"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddRemoveBadges
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Badges').visible"
    :isRemove="dialogs.find(item => item.name === 'Badges').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Badges').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddRemoveDiscounts
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Discounts').visible"
    :isRemove="dialogs.find(item => item.name === 'Discounts').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Discounts').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddWeclappDocuments
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'WeclappDocuments').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'WeclappDocuments').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddShopwareDocuments
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'ShopwareDocuments').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'ShopwareDocuments').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkSetShopOnlineStatus
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'OnlineStatus').visible"
    :isRemove="dialogs.find(item => item.name === 'OnlineStatus').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'OnlineStatus').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditPromotions
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Promotions').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'Promotions').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditMetatags
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Metatags').visible"
    :isRemove="dialogs.find(item => item.name === 'Metatags').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Metatags').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditCompliance
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Compliance').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'Compliance').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditTags
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Tags').visible"
    :isRemove="dialogs.find(item => item.name === 'Tags').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Tags').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditCustomFields
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'CustomFields').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'CustomFields').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
