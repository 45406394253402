<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="summaryTabVisible"
    :loading="loading"
    :dialogHeader="isRemove ? $t('labels.removeBadgesAndOrCategories') : $t('labels.addBadgesAndOrCategories')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.badges')">
            <BadgesList
                @selection-complete="onBadgesSelectionChange"
            ></BadgesList>
        </TabPanel>
        <TabPanel :header="$t('labels.categories')">
            <CategoriesTree
                :is-remove="isRemove"
                enable-parent-category-selection
                @selection-complete="onCategoriesSelectionChange"
            ></CategoriesTree>
        </TabPanel>
    </template>
    <template #summary-panel-header>
        <Panel class="mb-3" v-if="selectedBadges && selectedBadges.length > 0">
            <template #header>
                <div class="font-bold">{{ $t('labels.badges') }}</div>
            </template>
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                :paginator="selectedBadges && selectedBadges.length > 20"
                :rows="20"
                :value="selectedBadges"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column field="name" style="min-width: 12rem">
                    <template #body="{data}">
                        <span v-if="data.active">{{ data.name }}</span>
                        <span v-else class="text-gray-600"
                            >{{ data.name }}</span
                        >
                    </template>
                </Column>
            </DataTable>
        </Panel>
        <Panel
            class="mb-3"
            v-if="selectedCategoriesLabels && selectedCategoriesLabels.length > 0"
        >
            <template #header>
                <div class="font-bold">{{ $t('labels.categories') }}</div>
            </template>
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                :paginator="selectedCategoriesLabels && selectedCategoriesLabels.length > 20"
                :rows="20"
                :lazy="true"
                :value="selectedCategoriesLabels"
                responsiveLayout="scroll"
                paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rows-per-page-options="[10,20,50,100,200]"
                current-page-report-template="{first} to {last} of {totalRecords}"
            >
                <Column style="min-width: 12rem">
                    <template #body="{data}"> {{ data.label }} </template>
                </Column>
            </DataTable>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <template v-if="isRemove"
                ><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'ban']"
                />
            </template>
            <template v-else>
                <font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                /><font-awesome-icon
                    style="margin-left: 30px"
                    :icon="['fas', 'angle-double-down']"
                />
            </template>
        </h1>
    </template>
</BulkProductAction>
