<loading v-model:active="loading" />
<Panel :header="$t('labels.deliveryTime')">
    <DataTable
        class="p-datatable-sm text-sm"
        :paginator="true"
        :rows="10"
        style="height: 100%"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :lazy="true"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="products"
        :scrollable="true"
        :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
        :rows-per-page-options="[10,20,50,100,200]"
        :current-page-report-template="'{first} to {last} of {totalRecords}'"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        v-model:selection="selectedProducts"
        stateStorage="local"
        stateKey="products-delivery-time-list-state-session"
        @rowSelect="onRowSelect"
        @rowSelectAll="onRowSelectAll"
        @rowUnselect="onRowUnselect"
        @rowUnselectAll="onRowUnselectAll"
    >
        <Column
            selectionMode="multiple"
            style="min-width: 3rem; max-width: 6rem"
            ><template #header>
                <Badge :value="mergedSelection.length"></Badge>
                <i
                    v-if="mergedSelection.length > 0"
                    class="pi pi-times-circle text-red-600"
                    style="padding-left: 7px; cursor: pointer"
                    @click="clearSelection"
                ></i>
                <BulkActionsMenu
                    :mergedSelection="mergedSelection"
                    :totalRecords="totalRecords"
                    :filters="filters"
                    :visibleColumns="Object.keys(filters)"
                    :bulkItemsToPrepend="bulkOptions"
                    @force-data-refresh="onForceDataRefresh"
                ></BulkActionsMenu></template
        ></Column>
        <Column
            field="articleNumber"
            :sortable="true"
            :header="$t('labels.articleNumber')"
            style="min-width: 13rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                <div
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}"
                ></div>
                <div
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}"
                ></div>
                <span style="vertical-align: middle"
                    ><a
                        :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                        target="_blank"
                        >{{data.articleNumber}}</a
                    ></span
                >
                <!--<div :class="{'product-offline': !data?.shopwareData?.active}">
                    <span style="vertical-align: middle"
                        ><a
                            :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.articleNumber}}</a
                        ></span
                    >
                </div>
                <span v-if="!data?.shopwareData?.active" class="tooltip-text"
                    >{{$t('messages.offlineProduct')}}</span
                >-->
                <div class="text-xs" v-if="ekPriceCalculation(data)">
                    {{$t('labels.ekPrice')}}:
                    <b>{{ ekPriceCalculation(data) }} </b>
                </div>
                <div class="text-xs" v-if="uvpPriceCalculation(data)">
                    UVP:
                    <b>{{ uvpPriceCalculation(data) }} </b>
                </div>
            </template>
        </Column>
        <Column
            field="activeInAnyShop"
            dataType="boolean"
            bodyClass="text-center"
            style="min-width: 6rem"
            :header="$t('labels.productHome.activeInShop')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': (data.customAttributes?.article_active_in_shop || data.customAttributes?.article_active_in_shop2), 'text-red-600 pi-times-circle': !(data.customAttributes?.article_active_in_shop || data.customAttributes?.article_active_in_shop2)}"
                ></i>
            </template>
        </Column>
        <Column
            field="name"
            :sortable="true"
            style="min-width: 15rem"
            :header="$t('labels.name')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.name')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.name || data.shopwareData?.name }}</span
                >
            </template>
        </Column>
        <Column
            field="sku"
            :header="$t('labels.productAdd.sku')"
            style="min-width: 12rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.productAdd.sku')"
                />
            </template>
            <template #body="data">
                <div
                    v-if="data.data.articleSupplySources && data.data.articleSupplySources.length > 0"
                >
                    {{ data.data.articleSupplySources[0].articleNumber}}
                </div>
            </template>
        </Column>
        <Column
            field="supplierNumber"
            :header="$t('labels.salesOrder.supplierNumber')"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            style="min-width: 12rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.salesOrder.supplierNumber')"
                />
            </template>
            <template #body="data">
                <div
                    v-if="data.data.articleSupplySources && data.data.articleSupplySources.length > 0"
                    :class="{'product-supply-source-not-primary': filters.supplierNumber?.value && filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber}"
                >
                    {{ data.data.articleSupplySources[0].supplierNumber}}
                </div>
                <span
                    v-if="filters.supplierNumber?.value && filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber"
                    class="tooltip-text"
                    >{{$t('messages.nonPrimarySupplier')}}</span
                >
            </template>
        </Column>
        <Column
            :header="$t('labels.productAdd.supplier')"
            style="min-width: 8rem"
        >
            <template #body="data">
                <div
                    v-if="data.data.articleSupplySources && data.data.articleSupplySources.length > 0"
                    :class="{'product-supply-source-not-primary': filters.supplierNumber?.value && filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber}"
                    class="text-xs"
                >
                    {{ data.data.articleSupplySources[0].supplier.name}}
                </div>
                <span
                    v-if="filters.supplierNumber?.value && data.data.articleSupplySources && data.data.articleSupplySources.length > 0 && filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber"
                    class="tooltip-text text-xs"
                    >{{$t('messages.nonPrimarySupplier')}}</span
                >
            </template>
        </Column>

        <Column
            field="manufacturerName"
            :sortable="true"
            style="min-width: 12rem"
            :header="$t('labels.manufacturer')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.manufacturer')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.manufacturerName || data.shopwareData?.manufacturer
                    }}</span
                >
            </template>
        </Column>
        <Column
            field="blackWeekPromoFlag"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productPromotions.promoFlag')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_promo_flag, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_promo_flag)}"
                ></i>
            </template>
        </Column>
        <Column
            field="procurementLeadDays"
            header="WBT"
            :sortable="true"
            style="min-width: 8rem; cursor: pointer"
            :filterMatchModeOptions="matchModesNumeric"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputNumber
                    class="p-column-filter text-xs w-full"
                    autocomplete="off"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <div
                    v-if="filters?.supplierNumber?.value && data?.articleSupplySources && data.articleSupplySources.length > 0 && filters.supplierNumber.value !== data.articleSupplySources[0].supplierNumber"
                >
                    {{data.procurementLeadDays}}
                </div>
                <InputText
                    v-else
                    v-model="data[field]"
                    autofocus
                    class="w-full"
                />
            </template>
        </Column>
        <Column
            field="wbtDate"
            :filterMatchModeOptions="matchModeWithEmptyFilter"
            :sortable="true"
            header="WBT-Datum"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                <div v-if="data.data.customAttributes?.art_wbt_datum">
                    {{ new
                    Date(data.data.customAttributes.art_wbt_datum).toLocaleDateString("de-DE")
                    }}
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showWbtUpdateDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '20vw'}"
    :closable="false"
    :modal="true"
>
    <div class="grid p-fluid">
        <div class="field col">
            <label>{{$t('labels.productHome.procurementLeadDays')}}</label>
            <InputNumber
                autocomplete="off"
                v-model="bulkWbtUpdateValue"
                @input="forceUpdateWbtValue"
            />
        </div>
    </div>
    <div class="w-full mt-3 flex justify-content-between">
        <p-button severity="danger" @click="closeWbtBulkUpdateDialog(false)">
            {{ $t("buttons.cancel") }}
        </p-button>
        <p-button
            severity="success"
            :disabled="!bulkWbtUpdateValue"
            @click="closeWbtBulkUpdateDialog(true)"
        >
            {{ $t("buttons.confirm") }}
        </p-button>
    </div>
</PrimeDialog>
