import {ref, onMounted, computed} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';

import store from '@/store';
import {
    getAllAddresses,
    putManufacturerAddress
} from '@/services/manufacturers';
import {DateTime} from 'luxon';
import {i18n} from '@/utils/i18n';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Panel,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            filters.value = Object.assign({}, filterDefintions);
            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const toast = useToast();
        const editingRows = ref([]);

        const manufacturers = ref();

        const filterDefintions: any = {
            id: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            address: {value: null, matchMode: FilterMatchMode.CONTAINS},
            city: {value: null, matchMode: FilterMatchMode.CONTAINS},
            country: {value: null, matchMode: FilterMatchMode.CONTAINS},
            zipCode: {value: null, matchMode: FilterMatchMode.CONTAINS},
            phone: {value: null, matchMode: FilterMatchMode.CONTAINS},
            website: {value: null, matchMode: FilterMatchMode.CONTAINS},
            email: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getAllAddresses()
                .then((data) => {
                    manufacturers.value = (data.data.items || []).map(
                        (item: any) => {
                            return {
                                ...item,
                                lastModifiedAt: item.lastModifiedAtTimestamp
                                    ? DateTime.fromSeconds(
                                          item.lastModifiedAtTimestamp
                                      )
                                          .setLocale(i18n.global.locale)
                                          .setZone(
                                              process.env
                                                  ?.VUE_APP_DEFAULT_TIME_ZONE
                                          )
                                          .toLocaleString(
                                              DateTime.DATETIME_MED_WITH_SECONDS
                                          )
                                    : null
                            };
                        }
                    );
                    totalRecords.value = data.data.total;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('manufacturer-data-edit') !== -1;
        });

        const onRowEditComplete = (event: any) => {
            if (!event?.newData) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {lastModifiedAtTimestamp, lastModifiedAt, ...newData} =
                event.newData;
            putManufacturerAddress(newData || {})
                .then(() => {
                    loading.value = false;
                    loadLazyData();
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        return {
            manufacturers,
            dt,
            totalRecords,
            loading,
            filters,
            editPermissionAvailable,
            editingRows,
            onRowEditComplete
        };
    }
};
