import {i18n} from '@/utils/i18n';
import TieredMenu from 'primevue/tieredmenu';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import {computed, ref, watch} from 'vue';
import store from '@/store';
import {exportProductData} from '@/services/products';
import FileSaver from 'file-saver';
import {DateTime} from 'luxon';
import {isShopware5} from '@/utils/helpers';
import {getProfile} from '@/services/auth';
import * as papa from 'papaparse';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';
import BulkAddRemoveBadges from '@/components/dialog/products/bulk-add-remove-badges.vue';
import BulkAddRemoveDiscounts from '@/components/dialog/products/bulk-add-remove-discounts.vue';
import BulkAddWeclappDocuments from '@/components/dialog/products/bulk-add-weclapp-documents.vue';
import BulkAddShopwareDocuments from '@/components/dialog/products/bulk-add-shopware-documents.vue';
import BulkSetShopOnlineStatus from '@/components/dialog/products/bulk-set-shop-online-status.vue';
import BulkEditPromotions from '@/components/dialog/products/bulk-edit-promotions.vue';
import BulkEditMetatags from '@/components/dialog/products/bulk-edit-metatags.vue';
import BulkEditCompliance from '@/components/dialog/products/bulk-edit-compliance.vue';
import BulkEditTags from '@/components/dialog/products/bulk-edit-tags.vue';
import BulkEditCustomFields from '@/components/dialog/products/bulk-edit-custom-fields.vue';

export default {
    name: 'bulk-actions-menu',
    emits: ['force-data-refresh'],
    components: {
        TieredMenu,
        loading: LoadingPlugin,
        BulkAddAccessories,
        BulkAddRemoveBadges,
        BulkAddRemoveDiscounts,
        BulkAddWeclappDocuments,
        BulkAddShopwareDocuments,
        BulkSetShopOnlineStatus,
        BulkEditPromotions,
        BulkEditMetatags,
        BulkEditCompliance,
        BulkEditTags,
        BulkEditCustomFields
    },
    props: {
        mergedSelection: {
            type: Array,
            default: []
        },
        totalRecords: {
            type: Number,
            default: 0
        },
        filters: {
            type: Object,
            default: null
        },
        visibleColumns: {
            type: Array,
            default: []
        },
        bulkItemsToPrepend: {
            type: Array,
            default: []
        },
        bulkItemsToAppend: {
            type: Array,
            default: []
        }
    },

    setup(props: any, context: any) {
        const toast = useToast();
        const mergedSelection = ref(props.mergedSelection);
        const totalRecords = ref(props.totalRecords);
        const filters = ref(props.filters);
        const visibleColumns = ref(props.visibleColumns);
        const bulkItemsToPrepend = ref(props.bulkItemsToPrepend);
        const bulkItemsToAppend = ref(props.bulkItemsToAppend);
        const loading = ref(false);
        const bulkMenu = ref();

        watch(
            () => props.mergedSelection,
            (val) => {
                mergedSelection.value = val;
            }
        );

        watch(
            () => props.totalRecords,
            (val) => {
                totalRecords.value = val;
            }
        );

        watch(
            () => props.filters,
            (val) => {
                filters.value = val;
            }
        );

        watch(
            () => props.visibleColumns,
            (val) => {
                visibleColumns.value = val;
            }
        );

        watch(
            () => props.bulkItemsToPrepend,
            (val) => {
                bulkItemsToPrepend.value = val;
            }
        );

        watch(
            () => props.bulkItemsToAppend,
            (val) => {
                bulkItemsToAppend.value = val;
            }
        );

        const dialogDefinitions: Array<{
            name: string;
            visible: boolean;
            usingFilter: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }> = [
            {
                name: 'Comments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'Accessories',
                visible: false,
                usingFilter: false,
                mainProductSelected: false
            },
            {
                name: 'Badges',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Discounts',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'WeclappDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'ShopwareDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'OnlineStatus',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Promotions',
                visible: false,
                usingFilter: false
            },
            {
                name: 'Metatags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Tags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Compliance',
                visible: false,
                usingFilter: false
            },
            {
                name: 'CustomFields',
                visible: false,
                usingFilter: false
            }
        ];

        const dialogs = ref(dialogDefinitions);

        const tagsPermissionAvailable = computed(async () => {
            const user =
                (await store.getters['auth/user']) || (await getProfile());
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const bulkOptions = computed(() => {
            return bulkItemsToPrepend.value.concat(
                [
                    {
                        label: i18n.global.t('labels.articleAccessories'),
                        icon: 'pi pi-sitemap',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.useSelectedAsMainProduct'
                                ),
                                icon: 'pi pi-sitemap',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }
                                    openDialog({
                                        dialogName: 'Accessories',
                                        mainProductSelected: true
                                    });
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.useSelectedAsAccessory'
                                ),
                                icon: 'pi pi-link',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }
                                    openDialog({
                                        dialogName: 'Accessories'
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.productHome.activeInShop'),
                        icon: 'pi pi-shopping-cart',
                        items: [
                            {
                                label:
                                    i18n.global.t('labels.putSelectedOffline', {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }) + '...',
                                icon: 'pi pi-circle-fill text-red-600',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({
                                        dialogName: 'OnlineStatus',
                                        isRemove: true
                                    });
                                }
                            },
                            {
                                label:
                                    i18n.global.t('labels.putSelectedOnline', {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }) + '...',
                                icon: 'pi pi-circle-fill text-green-600',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({
                                        dialogName: 'OnlineStatus'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t('labels.putAllOffline', {
                                    number: totalRecords.value
                                }),
                                icon: 'pi pi-circle-fill text-red-600',
                                command: () => {
                                    openDialog({
                                        dialogName: 'OnlineStatus',
                                        isRemove: true,
                                        usingFilter: true
                                    });
                                }
                            },
                            {
                                label: i18n.global.t('labels.putAllOnline', {
                                    number: totalRecords.value
                                }),
                                icon: 'pi pi-circle-fill text-green-600',
                                command: () => {
                                    openDialog({
                                        dialogName: 'OnlineStatus',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label:
                            i18n.global.t('labels.badges') +
                            ' & ' +
                            i18n.global.t('labels.categories'),
                        icon: 'pi pi-tag',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addBadgesToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-tag',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }

                                    if (
                                        selectedProductsInShopware.value
                                            .length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({dialogName: 'Badges'});
                                }
                            },
                            {
                                label: i18n.global.t('labels.addBadgesToAll', {
                                    number: totalRecords.value
                                }),
                                icon: 'pi pi-tag',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Badges',
                                        usingFilter: true
                                    });
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.removeBadgesFromSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-trash',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }

                                    if (
                                        selectedProductsInShopware.value
                                            .length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({
                                        dialogName: 'Badges',
                                        isRemove: true
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.removeBadgesFromAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-trash',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Badges',
                                        isRemove: true,
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.discounts'),
                        icon: 'pi pi-euro',
                        disabled:
                            isShopware5(
                                resolvedPlatform.value?.threeLetterId
                            ) ||
                            !selectedProductsInShopware.value ||
                            selectedProductsInShopware.value.length < 1,
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addDiscountsToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-sitemap',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }
                                    if (
                                        selectedProductsInShopware.value
                                            .length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                            )
                                        );
                                        return;
                                    }
                                    openDialog({
                                        dialogName: 'Discounts'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.addDiscountsToAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-sitemap',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Discounts',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.weclappDocuments'),
                        icon: 'pi pi-file-pdf',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addWeclappDocumentsToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-file-pdf',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({
                                        dialogName: 'WeclappDocuments'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.addWeclappDocumentsToAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-file-pdf',
                                command: () => {
                                    openDialog({
                                        dialogName: 'WeclappDocuments',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.shopwareDocuments'),
                        icon: 'pi pi-file-pdf',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addShopwareDocumentsToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-file-pdf',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    if (
                                        !mergedSelection.value ||
                                        mergedSelection.value.length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProduct'
                                            )
                                        );
                                        return;
                                    }
                                    if (
                                        selectedProductsInShopware.value
                                            .length < 1
                                    ) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                            )
                                        );
                                        return;
                                    }

                                    openDialog({
                                        dialogName: 'ShopwareDocuments'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.addShopwareDocumentsToAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-file-pdf',
                                command: () => {
                                    openDialog({
                                        dialogName: 'ShopwareDocuments',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.promotions'),
                        icon: 'pi pi-percentage',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.editPromotionsOnSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({dialogName: 'Promotions'});
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.editPromotionsOnAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Promotions',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.productHome.metaTagsSEO'),
                        icon: 'pi pi-tags',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addMetatagsToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-tag',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({
                                        dialogName: 'Metatags'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.addMetatagsToAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-tag',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Metatags',
                                        usingFilter: true
                                    });
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.removeMetatagsFromSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-trash',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({
                                        dialogName: 'Metatags',
                                        isRemove: true
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.removeMetatagsFromAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-trash',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Metatags',
                                        isRemove: true,
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },

                    {
                        label: i18n.global.t('labels.compliance'),
                        icon: 'pi pi-check-square',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.editComplianceOnSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({dialogName: 'Compliance'});
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.editComplianceOnAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Compliance',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.tags'),
                        icon: 'pi pi-tags',
                        disabled: !tagsPermissionAvailable.value,
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.addTagsToSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                class: 'font-normal',
                                icon: 'pi pi-tag',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({
                                        dialogName: 'Tags'
                                    });
                                }
                            },
                            {
                                label: i18n.global.t('labels.addTagsToAll', {
                                    number: totalRecords.value
                                }),
                                class: 'font-normal',
                                icon: 'pi pi-tag',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Tags',
                                        usingFilter: true
                                    });
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.removeTagsFromSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                class: 'font-normal',
                                icon: 'pi pi-trash',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({
                                        dialogName: 'Tags',
                                        isRemove: true
                                    });
                                }
                            },
                            {
                                label: i18n.global.t(
                                    'labels.removeTagsFromAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                class: 'font-normal',
                                icon: 'pi pi-trash',
                                command: () => {
                                    openDialog({
                                        dialogName: 'Tags',
                                        isRemove: true,
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.customAttributes'),
                        icon: 'pi pi-book',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.editCustomFieldsOnSelected',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                disabled: mergedSelection.value.length < 1,
                                command: () => {
                                    openDialog({dialogName: 'CustomFields'});
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.editCustomFieldsOnAll',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-pencil',
                                command: () => {
                                    openDialog({
                                        dialogName: 'CustomFields',
                                        usingFilter: true
                                    });
                                }
                            }
                        ]
                    },
                    {
                        label: i18n.global.t('labels.export'),
                        icon: 'pi pi-file-export',
                        items: [
                            {
                                label: i18n.global.t(
                                    'labels.exportSelectedProducts',
                                    {
                                        number:
                                            mergedSelection.value?.length || ''
                                    }
                                ),
                                icon: 'pi pi-file-excel',
                                disabled: mergedSelection.value.length < 1,
                                command: async () => {
                                    loading.value = true;
                                    try {
                                        const result = await exportProductData(
                                            visibleColumns.value,
                                            mergedSelection.value,
                                            filters.value,
                                            false
                                        );

                                        FileSaver.saveAs(
                                            new Blob(
                                                [
                                                    papa.unparse(
                                                        result.data?.items ||
                                                            [],
                                                        {
                                                            delimiter: ';'
                                                        }
                                                    )
                                                ],
                                                {
                                                    type: 'text/csv;charset=utf-8'
                                                }
                                            ),
                                            'Bulk-Export-Products-' +
                                                DateTime.fromJSDate(
                                                    new Date()
                                                ).toISODate() +
                                                '.csv'
                                        );
                                    } catch (error: any) {
                                        toast.error(
                                            error.response?.data?.error ||
                                                error.message
                                        );
                                    } finally {
                                        loading.value = false;
                                    }
                                }
                            },

                            {
                                label: i18n.global.t(
                                    'labels.exportAllProducts',
                                    {
                                        number: totalRecords.value
                                    }
                                ),
                                icon: 'pi pi-file-excel',
                                command: async () => {
                                    await exportProductData(
                                        visibleColumns.value,
                                        mergedSelection.value,
                                        filters.value,
                                        true
                                    );

                                    toast.success(
                                        i18n.global.t(
                                            'messages.cronJobExecutionScheduled'
                                        )
                                    );
                                }
                            }
                        ]
                    }
                ],
                bulkItemsToAppend.value
            );
        });

        const closeDialog = () => {
            dialogs.value.forEach((item) => {
                item.visible = false;
            });
        };

        const openDialog = (dialogParameters: {
            dialogName: string;
            usingFilter?: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }) => {
            closeDialog();
            const chosenDialog = dialogs.value.find(
                (item) => item.name === dialogParameters.dialogName
            );
            chosenDialog.visible = true;
            chosenDialog.usingFilter = dialogParameters.usingFilter || false;
            if (typeof chosenDialog['mainProductSelected'] !== 'undefined') {
                chosenDialog.mainProductSelected =
                    dialogParameters.mainProductSelected || false;
            }

            if (typeof chosenDialog['isRemove'] !== 'undefined') {
                chosenDialog.isRemove = dialogParameters.isRemove || false;
            }
        };

        const selectedProductsInShopware = computed(() => {
            return mergedSelection.value
                ? mergedSelection.value.filter((item: any) => item.shopwareData)
                : [];
        });

        const onCloseBulkBadgesCategoriesDialog = (
            forceResfresh: boolean = false
        ) => {
            closeDialog();
            if (forceResfresh) {
                context.emit('force-data-refresh');
            }
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        return {
            bulkOptions,
            dialogs,
            onCloseBulkBadgesCategoriesDialog,
            bulkMenu,
            bulkMenuToggle,
            selectedProductsInShopware
        };
    }
};
