<loading v-model:active="loading" />
<PrimePanel class="panel-container-datatable">
    <template #header>
        <div class="w-full flex justify-content-center">
            <div class="mt-2">
                <SalesChannelSwitcher></SalesChannelSwitcher>
            </div>
        </div>
    </template>
    <DataTable
        class="p-datatable-sm text-sm"
        paginator
        :rows="10"
        lazy
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="products"
        :scrollable="true"
        :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
        :rows-per-page-options="[10,20,50,100,200]"
        :current-page-report-template="'{first} to {last} of {totalRecords}'"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        v-model:expandedRows="expandedRows"
        v-model:selection="selectedProducts"
        stateStorage="local"
        stateKey="products-webshop-view-state-session"
        @rowSelect="onRowSelect"
        @rowSelectAll="onRowSelectAll"
        @rowUnselect="onRowUnselect"
        @rowUnselectAll="onRowUnselectAll"
    >
        <Column
            :expander="true"
            style="min-width: 4rem; max-width: fit-content"
            class="tt-filter-menu-th"
            ><template #filter>
                <FilterMenu
                    parent-component-id="product-webshop-view-list"
                    :parent-filters="filters"
                    @clear-filters="clearFilters"
                    @apply-filters="applyFilters"
                /> </template
        ></Column>
        <Column
            selectionMode="multiple"
            style="min-width: 3rem; max-width: 6rem"
            ><template #header>
                <Badge :value="mergedSelection.length"></Badge>
                <i
                    v-if="mergedSelection.length > 0"
                    class="pi pi-times-circle text-red-600"
                    style="padding-left: 7px; cursor: pointer"
                    @click="clearSelection"
                ></i>
                <BulkActionsMenu
                    :mergedSelection="mergedSelection"
                    :totalRecords="totalRecords"
                    :filters="filters"
                    :visibleColumns="Object.keys(filters)"
                    @force-data-refresh="onCloseBulkBadgesCategoriesDialog"
                ></BulkActionsMenu></template
        ></Column>
        <Column
            field="articleNumber"
            sortable
            :header="$t('labels.articleNumber')"
            style="min-width: 13rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                <div class="flex align-content-center">
                    <span style="vertical-align: middle" class="mr-2"
                        ><a
                            :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.articleNumber}}</a
                        ></span
                    >
                    <a
                        v-if="(data.shopwareData?.active && data.shopwareData?.name && data.shopwareUrl)"
                        target="_blank"
                        :href="resolvedShopwareUrl(data.shopwareUrl, data.shopwareData, resolvedPlatform?.threeLetterId)"
                    >
                        <i
                            class="pi pi-shopping-cart mr-2"
                            :class="{'sales-channel-teltec-color':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata-color':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active}"
                        ></i>
                    </a>
                    <a
                        v-if="(data.secondShopwareData?.active && data.secondShopwareData?.name && data.secondShopwareUrl)"
                        target="_blank"
                        :href="resolvedShopwareUrl(data.secondShopwareUrl, data.secondShopwareData, secondPlatform?.threeLetterId)"
                    >
                        <i
                            class="pi pi-shopping-cart mr-2"
                            :class="{'sales-channel-videodata-color':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec-color':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active}"
                        ></i>
                    </a>
                </div>
            </template>
        </Column>
        <Column
            field="name"
            sortable
            style="min-width: 12rem"
            :header="$t('labels.articleName')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.name')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.name || data.shopwareData?.name }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.name || null, data.shopwareData.name || null)"
                    class="pi pi-exclamation-triangle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.name')"
                ></span>
                <div class="mt-2">
                    <Tag
                        v-for="tag in data.tags"
                        severity="info"
                        class="mt-1 mr-1"
                    >
                        <span
                            >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')}}</span
                        >
                    </Tag>
                </div>
            </template>
        </Column>
        <Column
            field="ean"
            sortable
            style="min-width: 10rem"
            :header="$t('labels.ean')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.ean')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.ean || data.shopwareData?.ean }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.ean || null, data.shopwareData.ean || null)"
                    class="pi pi-exclamation-triangle"
                    style="margin-left: 0.5rem; vertical-align: middle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.ean')"
                ></span>
            </template>
        </Column>
        <Column
            field="manufacturerPartNumber"
            sortable
            style="min-width: 10rem"
            :header="$t('labels.mpn')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.mpn')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.manufacturerPartNumber || data.shopwareData?.mpn
                    }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.manufacturerPartNumber || null, data.shopwareData.mpn || null)"
                    class="pi pi-exclamation-triangle"
                    style="margin-left: 0.5rem; vertical-align: middle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.mpn')"
                ></span>
            </template>
        </Column>
        <Column
            field="activeInShopware"
            dataType="boolean"
            bodyClass="text-center"
            style="min-width: 10rem"
        >
            <template #header>
                <div class="flex align-items-center text-center">
                    <div>
                        {{$t('labels.productHome.activeInShop')}}
                        <span
                            class="sales-channel-dot w-1rem h-1rem"
                            :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                        ></span>
                    </div></div
            ></template>
            <template #filter="{filterModel, filterCallback}">
                <div class="w-full flex justify-content-center ml-3">
                    <div>
                        <TriStateCheckbox
                            v-model="filterModel.value"
                            @change="filterCallback()"
                        />
                    </div>
                </div>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.shopwareData?.active, 'text-red-600 pi-times-circle': !(data.shopwareData?.active)}"
                ></i>
            </template>
        </Column>
        <Column
            field="activeInShopware"
            dataType="boolean"
            bodyClass="text-center"
            style="min-width: 10rem"
        >
            <template #header>
                <div class="flex align-items-center text-center">
                    <div>
                        {{$t('labels.productHome.activeInShop')}}
                        <span
                            class="sales-channel-dot w-1rem h-1rem"
                            :class="{'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId)}"
                        ></span>
                    </div></div
            ></template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.secondShopwareData?.active, 'text-red-600 pi-times-circle': !(data.secondShopwareData?.active)}"
                ></i>
            </template>
        </Column>
        <Column
            field="shopImageExists"
            dataType="boolean"
            style="min-width: 10rem"
            bodyClass="text-center"
        >
            <template #header>
                <div class="flex align-items-center text-center">
                    <div>
                        {{$t('labels.imageExists')}}
                        <span
                            class="sales-channel-dot w-1rem h-1rem ml-1"
                            :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                        ></span>
                    </div></div
            ></template>
            <template #filter="{filterModel, filterCallback}">
                <div class="w-full flex justify-content-center ml-3">
                    <div>
                        <TriStateCheckbox
                            v-model="filterModel.value"
                            @change="filterCallback()"
                        />
                    </div>
                </div>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': (data?.shopwareData?.imageCount > 0) , 'text-red-600 pi-times-circle': (!(data?.shopwareData?.imageCount)) }"
                ></i>
            </template>
        </Column>
        <Column style="min-width: 10rem" bodyClass="text-center">
            <template #header>
                <div class="flex align-items-center text-center">
                    <div>
                        {{$t('labels.imageExists')}}
                        <span
                            class="sales-channel-dot w-1rem h-1rem ml-1"
                            :class="{'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId)}"
                        ></span>
                    </div></div
            ></template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': (data?.secondShopwareData?.imageCount > 0) , 'text-red-600 pi-times-circle': (!(data?.secondShopwareData?.imageCount)) }"
                ></i>
            </template>
        </Column>
        <Column frozen alignFrozen="right">
            <template #body="{data}" v-if="editPermissionAvailable">
                <router-link
                    :to="{
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber},
                                    state: {webshopView: true, historyFilters: historyFilters}
                                }"
                    ><i
                        class="pi pi-pencil text-color"
                        v-tooltip.left="$t('labels.articleDetails')"
                    ></i
                ></router-link>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div
                v-if="slotProps.data?.shopwareData?.description || slotProps.data?.description"
                v-html="slotProps.data?.shopwareData?.description || slotProps.data?.description"
            ></div>
            <div v-else>No description available.</div>
        </template>
    </DataTable>
</PrimePanel>
