import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.manufacturers') + ' - ' + _ctx.$t('labels.addresses')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.manufacturers,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          editMode: "row",
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[2] || (_cache[2] = $event => ((_ctx.editingRows) = $event)),
          onRowEditSave: _ctx.onRowEditComplete
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "id",
              sortable: "",
              style: {"min-width":"12rem"},
              header: "Marke"
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: 'Marke'
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "name",
              sortable: "",
              style: {"min-width":"16rem"},
              header: _ctx.$t('labels.manufacturerName')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.manufacturerName')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-12rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "address",
              sortable: "",
              style: {"min-width":"16rem"},
              header: _ctx.$t('labels.address')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.address')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-12rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "city",
              sortable: "",
              style: {"min-width":"12rem"},
              header: _ctx.$t('labels.city')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.city')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-10rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "zipCode",
              sortable: "",
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.zipCode')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.zipCode')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-6rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "country",
              sortable: "",
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.country')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.country')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-6rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "email",
              sortable: "",
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.email')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.email')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-12rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "website",
              sortable: "",
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.website')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.website')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-12rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "phone",
              sortable: "",
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.phoneNumber')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.phoneNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  class: "p-column-filter text-sm w-10rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "lastModifiedAtTimestamp",
              sortable: "",
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.productHome.lastUpdate')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.lastModifiedAt), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  frozen: "",
                  alignFrozen: "right",
                  rowEditor: "",
                  style: {"min-width":"8rem"},
                  bodyStyle: "text-align:center"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "editingRows", "onRowEditSave"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}