import {
    addColumnMapping,
    getColumnMappingConfiguration
} from '@/services/import';
import {i18n} from '@/utils/i18n';
import {computed, onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import {IHeaderMappingResultColumn} from '@/interfaces/import-wizard/header-mapping-result-column';
import {helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    name: 'HeaderMappingGrid',
    emits: ['column-mapping-saved'],
    components: {
        'p-button': Button,
        DataTable,
        Column,
        'p-dropdown': Dropdown
    },
    props: {
        savedFileId: Number
    },
    setup(props: any, context: any) {
        const columnConfiguration: any = ref({
            columnArray: [],
            fileHeader: [],
            rowExample: []
        });

        const toast = useToast();

        const state = ref({
            itemsToSubmit: []
        });

        const submitted = ref(false);

        const rules = {
            itemsToSubmit: {
                $each: helpers.forEach({
                    header: {},
                    mapped: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            (value: any, row: any) => {
                                return !row.mandatory || !!value;
                            }
                        )
                    },
                    mandatory: {}
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        onMounted(async () => {
            const response = await getColumnMappingConfiguration(
                props.savedFileId
            );
            Object.assign(columnConfiguration.value, response);

            state.value.itemsToSubmit =
                columnConfiguration.value.columnArray.map(
                    (item: IHeaderMappingResultColumn) => {
                        return {
                            header: item.header,
                            mapped: columnConfiguration.value.fileHeader.find(
                                (fh: string) => fh.trim() === item.header.trim()
                            ),
                            mandatory: item.required
                        };
                    }
                );
        });

        const getRowExample = (header: string) => {
            const found: {
                header: string;
                mapped: string;
                mandatory: boolean;
            } | null = state.value.itemsToSubmit.find(
                (element: {header: string}) => element.header === header
            );

            if (!found) {
                return '';
            }

            const fileHeaderIndex =
                columnConfiguration.value.fileHeader.indexOf(found.mapped);

            return fileHeaderIndex !== -1
                ? columnConfiguration.value.rowExample[fileHeaderIndex]
                : '';
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            const tmp = state.value.itemsToSubmit.map((item) => item);

            const duplicate = tmp.find((nnn, index) => {
                return tmp.find(
                    (x, ind) =>
                        !!x.mapped && x.mapped === nnn.mapped && index !== ind
                );
            });

            if (duplicate) {
                toast.error(
                    `${duplicate.mapped} ${i18n.global.t(
                        'messages.elementIsDuplicate'
                    )}.`
                );
                return;
            }

            try {
                await addColumnMapping(
                    props.savedFileId,
                    state.value.itemsToSubmit
                );
                context.emit('column-mapping-saved', true);
            } catch (error: any) {
                toast.error(error.message);
            }
        };

        const items = computed(() => columnConfiguration.value.columnArray);

        const options = computed(() => columnConfiguration.value.fileHeader);

        return {
            options,
            getRowExample,
            handleSubmit,
            submitted,
            items,
            state,
            v$
        };
    }
};
