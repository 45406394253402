<loading v-model:active="savingInProgress" />
<Panel>
    <template #header>
        <div class="flex justify-content-between w-full">
            <div class="font-bold">Shop</div>
            <i
                v-if="!isEdit"
                class="pi pi-pencil"
                @click="isEdit = true;"
                style="cursor: pointer"
            ></i>
            <div v-else>
                <i
                    class="pi pi-check text-green-600"
                    style="cursor: pointer"
                    @click="handleSubmit(!v$.$invalid)"
                ></i>
                <i
                    class="pi pi-times-circle text-red-600 ml-3"
                    style="cursor: pointer"
                    @click="resetForm"
                ></i>
            </div>
        </div>
    </template>
    <div class="grid mb-3">
        <div class="col">{{$t('labels.customerNumber')}}</div>
        <div class="col">{{shopData?.customer?.customerNumber}}</div>
    </div>
    <div class="grid" :class="{'mb-3': !isEdit}">
        <div class="col">{{$t('labels.email')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{shopData?.customer?.email}}
                <a
                    v-if="shopData?.customer?.email"
                    :href="'https://' + shopData.customer.email.split('@').pop()"
                    target="_blank"
                >
                    <i
                        class="pi pi-external-link ml-1"
                        style="cursor: pointer"
                    ></i> </a
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.email.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.email.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.email, v$.email.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.email.$model = weclappFormBaseData?.email"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.email.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-3': !isEdit}">
        <div class="col">{{$t('labels.taxId')}}</div>
        <div class="col">
            <div v-if="!isEdit">
                <span class="mr-2">{{shopData?.customer?.vatNumber}}</span>
                <template v-if="checkVatNumberCountryResolved">
                    <OverlayPanel ref="op" class="text-sm vat-number-overlay">
                        <div v-if="vatNumberData" class="mb-3">
                            <template v-if="vatNumberData.valid">
                                <i
                                    class="pi text-green-600 pi-check-circle mr-2"
                                ></i
                                >MwSt-Nummer gültig
                            </template>
                            <template v-else>
                                <i
                                    class="pi text-red-600 pi-times-circle mr-2"
                                ></i
                                >MwSt-Nummer ungültig
                            </template>
                        </div>
                        <div v-if="vatNumberData?.name" class="mb-3">
                            <span class="font-bold mr-3"
                                >{{$t('labels.user.displayName')}}</span
                            >{{vatNumberData.name}}
                            <i
                                v-if="vatNumberData.valid && isEdit"
                                class="pi pi-copy ml-2"
                                style="cursor: pointer"
                                @click="copyToClipboard(vatNumberData.name, $event)"
                                v-tooltip.left="$t('labels.copyToClipboard')"
                            ></i>
                        </div>
                        <div v-if="vatNumberData?.address">
                            <span class="font-bold mr-3"
                                >{{$t('labels.address')}}</span
                            >{{vatNumberData.address}}<i
                                v-if="vatNumberData.valid && isEdit"
                                class="pi pi-copy ml-2"
                                style="cursor: pointer"
                                @click="copyToClipboard(vatNumberData.address, $event)"
                                v-tooltip.left="$t('labels.copyToClipboard')"
                            ></i>
                        </div>
                    </OverlayPanel>
                    <i
                        class="pi pi-info-circle text-xl text-green-600"
                        style="cursor: pointer"
                        @click="toggleOverlay"
                    ></i
                ></template>
            </div>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.vatNumber.$model"
                    :class="{'p-invalid':v$.vatNumber.$invalid && submitted}"
                />
                <template v-if="checkVatNumberCountryResolved">
                    <OverlayPanel ref="op" class="text-sm vat-number-overlay">
                        <div v-if="vatNumberData" class="mb-3">
                            <template v-if="vatNumberData.valid">
                                <i
                                    class="pi text-green-600 pi-check-circle mr-2"
                                ></i
                                >MwSt-Nummer gültig
                            </template>
                            <template v-else>
                                <i
                                    class="pi text-red-600 pi-times-circle mr-2"
                                ></i
                                >MwSt-Nummer ungültig
                            </template>
                        </div>
                        <div v-if="vatNumberData?.name" class="mb-3">
                            <span class="font-bold mr-3"
                                >{{$t('labels.user.displayName')}}</span
                            >{{vatNumberData.name}}
                            <i
                                v-if="vatNumberData.valid && isEdit"
                                class="pi pi-copy ml-2"
                                style="cursor: pointer"
                                @click="copyToClipboard(vatNumberData.name, $event)"
                                v-tooltip.left="$t('labels.copyToClipboard')"
                            ></i>
                        </div>
                        <div v-if="vatNumberData?.address">
                            <span class="font-bold mr-3"
                                >{{$t('labels.address')}}</span
                            >{{vatNumberData.address}}<i
                                v-if="vatNumberData.valid && isEdit"
                                class="pi pi-copy ml-2"
                                style="cursor: pointer"
                                @click="copyToClipboard(vatNumberData.address, $event)"
                                v-tooltip.left="$t('labels.copyToClipboard')"
                            ></i>
                        </div>
                    </OverlayPanel>
                    <i
                        class="pi pi-info-circle text-xl text-green-600 ml-3"
                        style="cursor: pointer"
                        @click="toggleOverlay"
                    ></i
                ></template>
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.taxId, v$.vatNumber.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.vatNumber.$model = weclappFormBaseData?.taxId"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.vatNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>

    <div class="grid mt-3 mb-3">
        <div class="col font-bold">
            {{$t('labels.address')}}
            <div
                class="mt-2"
                v-if="!shopData?.hasSameShippingAddress && shopData?.shippingAddress"
            >
                <Tag severity="danger">
                    {{$t('labels.alternativeDeliveryAddress')}}
                </Tag>
            </div>
        </div>
        <div class="col">
            <p-dropdown
                class="w-full"
                @change="resetForm"
                v-model="selectedAddressType"
                optionValue="type"
                :options="[{type: 'billing', ...shopData?.billingAddress}, {type: 'shipping', ...shopData?.shippingAddress} ]"
                v-if="!isEdit && (shopData && !shopData.hasSameShippingAddress)"
            >
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                        <div>
                            {{ selectedShopAddress?.street }}<span
                                v-if="selectedShopAddress?.additionalAddressLine1"
                                >, {{
                                selectedShopAddress?.additionalAddressLine1
                                }}</span
                            >, {{selectedShopAddress?.zipcode}},
                            {{selectedShopAddress?.city}},
                            {{resolveCountryFromCode(selectedShopAddress?.country)}}
                        </div>
                        <i
                            v-if="selectedAddressType === 'billing'"
                            class="pi pi-calculator ml-2"
                        ></i>
                        <i
                            v-if="selectedAddressType === 'shipping'"
                            class="pi pi-truck ml-2"
                        ></i>
                    </div>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>
                            {{ slotProps.option.street }}<span
                                v-if="slotProps.option.additionalAddressLine1"
                                >, {{ slotProps.option.additionalAddressLine1
                                }}</span
                            >, {{slotProps.option.zipcode}},
                            {{slotProps.option.city}},
                            {{resolveCountryFromCode(slotProps.option.country)}}
                        </div>
                        <i
                            v-if="slotProps.option.type === 'billing'"
                            class="pi pi-calculator ml-2"
                        ></i>
                        <i
                            v-if="slotProps.option.type === 'shipping'"
                            class="pi pi-truck ml-2"
                        ></i>
                    </div>
                </template>
            </p-dropdown>
            <div v-else>
                <i
                    v-if="shopData?.billingAddress && (!isEdit || selectedAddressType === 'billing')"
                    class="pi pi-calculator ml-2"
                ></i>
                <i
                    v-if="shopData?.hasSameShippingAddress || (isEdit && selectedAddressType === 'shipping')"
                    class="pi pi-truck ml-2"
                ></i>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col flex justify-content-between">
            <div>{{$t('labels.company')}}</div>
            <i
                v-if="isEdit && v$?.company?.$model && !v$?.department?.$model && v$?.firstName?.$model && v$?.lastName?.$model"
                class="pi pi-user-plus text-xl"
                style="cursor: pointer; margin-top: 8px; margin-right: -15px"
                @click="v$.department.$model = v$.company.$model; v$.company.$model = (selectedShopAddress?.salutation + ' ' + v$.firstName.$model + ' ' + v$.lastName.$model).trim()"
            ></i>
        </div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.company}}
                <span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.company || resolveSelectShopAddressPropertyValue('company')) && selectedShopAddress?.company !== resolveSelectShopAddressPropertyValue('company')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.company, customer: resolveSelectShopAddressPropertyValue('company') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.company.$model"
                    :class="{'p-invalid':v$.company.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.company, v$.company.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.company.$model = weclappFormBaseData?.company"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.company.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.company2')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.department}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.department || resolveSelectShopAddressPropertyValue('department')) && selectedShopAddress?.department !== resolveSelectShopAddressPropertyValue('department')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.department, customer: resolveSelectShopAddressPropertyValue('department') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.department.$model"
                    :class="{'p-invalid':v$.department.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.department, v$.department.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.department.$model = weclappFormBaseData?.department"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.department.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.street')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.street}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.street || resolveSelectShopAddressPropertyValue('street')) && selectedShopAddress?.street !== resolveSelectShopAddressPropertyValue('street')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.street, customer: resolveSelectShopAddressPropertyValue('street') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.street.$model"
                    :class="{'p-invalid':v$.street.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.street, v$.street.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.street.$model = weclappFormBaseData?.street"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div
        class="grid"
        :class="{'mb-1': !isEdit}"
        v-if="isEdit || selectedShopAddress?.additionalAddressLine1"
    >
        <div class="col">{{$t('labels.street2')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.additionalAddressLine1}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.additionalAddressLine1 || resolveSelectShopAddressPropertyValue('additionalAddressLine1')) && selectedShopAddress?.additionalAddressLine1 !== resolveSelectShopAddressPropertyValue('additionalAddressLine1')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.additionalAddressLine1, customer: resolveSelectShopAddressPropertyValue('additionalAddressLine1') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.street2.$model"
                    :class="{'p-invalid':v$.street2.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.street2, v$.street2.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.street2.$model = weclappFormBaseData?.street2"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street2.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.zipCode')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.zipcode}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.zipcode || resolveSelectShopAddressPropertyValue('zipcode')) && selectedShopAddress?.zipcode !== resolveSelectShopAddressPropertyValue('zipcode')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.zipcode, customer: resolveSelectShopAddressPropertyValue('zipcode') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.zipcode.$model"
                    :class="{'p-invalid':v$.zipcode.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.zipcode, v$.zipcode.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.zipcode.$model = weclappFormBaseData?.zipcode"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.zipcode.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.city')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.city}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.city || resolveSelectShopAddressPropertyValue('city')) && selectedShopAddress?.city !== resolveSelectShopAddressPropertyValue('city')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.city, customer: resolveSelectShopAddressPropertyValue('city') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.city.$model"
                    :class="{'p-invalid':v$.city.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.city, v$.city.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.city.$model = weclappFormBaseData?.city"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.city.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-3': true}">
        <div class="col">{{$t('labels.country')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{resolveCountryFromCode(selectedShopAddress?.country)}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.country || resolveSelectShopAddressPropertyValue('country')) && selectedShopAddress?.country !== resolveSelectShopAddressPropertyValue('country')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.country, customer: resolveSelectShopAddressPropertyValue('country') })"
                ></span
            ></span>
            <div v-else>
                <p-dropdown
                    class="text-sm w-7"
                    v-model="v$.country.$model"
                    optionLabel="label"
                    optionValue="value"
                    :class="{'p-invalid':v$.country.$invalid && submitted}"
                    :options="countries"
                >
                </p-dropdown>
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.country, v$.country.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.country.$model = weclappFormBaseData?.country"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.country.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-5': !isEdit}">
        <div class="col">{{$t('labels.phoneNumber')}}</div>
        <div class="col">
            <span v-if="!isEdit">
                {{selectedShopAddress?.phoneNumber}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.phoneNumber || resolveSelectShopAddressPropertyValue('phoneNumber')) && selectedShopAddress?.phoneNumber !== resolveSelectShopAddressPropertyValue('phoneNumber')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.phoneNumber, customer: resolveSelectShopAddressPropertyValue('phoneNumber') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.phoneNumber.$model"
                    :class="{'p-invalid':v$.phoneNumber.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.phoneNumber, v$.phoneNumber.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.phoneNumber.$model = weclappFormBaseData?.phoneNumber"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.phoneNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid mt-3 mb-1" v-if="isEdit && v$?.$anyDirty">
        <div class="col mt-1">
            {{$t('messages.saveCustomerDataAsWellTooltip')}}<span
                class="pi pi-question-circle ml-1"
                v-tooltip.top="$t(selectedAddressType === 'billing' ? (shopData?.hasSameShippingAddress ? 'messages.changesWillBeSavedinCustomerDefaultAddresses' : 'messages.changesWillBeSavedinCustomerDefaultBillingAddress') : 'messages.changesWillBeSavedinCustomerDefaultShippingAddress')"
            ></span>
        </div>
        <div class="col">
            <InputSwitch
                class="text-sm"
                v-model="v$.saveDataInCustomer.$model"
            />
        </div>
    </div>
    <!--<div
        v-if="!shopData?.hasSameShippingAddress && shopData?.shippingAddress"
        class="grid mt-5 mb-3 h-6rem"
    >
        <div class="col font-bold">
            {{$t('labels.alternativeDeliveryAddress')}}
        </div>
        <div class="col text-sm">
            <div>
                <div v-if="shopData?.shippingAddress?.company">
                    {{shopData?.shippingAddress?.company}}
                </div>
                <div>{{shopData?.shippingAddress?.street}}</div>
                <div v-if="shopData?.shippingAddress?.additionalAddressLine1">
                    {{shopData?.shippingAddress?.additionalAddressLine1}}
                </div>
                <div>
                    {{shopData?.shippingAddress?.zipcode}}
                    {{shopData?.shippingAddress?.city}}
                </div>
                <div v-if="shopData?.shippingAddress?.country">
                    {{resolveCountryFromCode(shopData?.shippingAddress?.country)}}
                    <span
                        class="pi pi-exclamation-triangle"
                        v-if="shopData?.shippingAddress?.country !== shopData?.billingAddress?.country"
                        v-tooltip.top="$t('messages.invoiceAnddeliveryCountryDiffer')"
                    ></span>
                </div>
            </div>
        </div>
    </div>-->
    <div class="grid mt-5 mb-3">
        <div class="col font-bold">{{$t('labels.contactPersonWcp')}}</div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.salutation')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{selectedShopAddress?.salutation}}</span>
            <div v-else>
                <p-dropdown
                    class="w-7"
                    v-model="v$.salutation.$model"
                    optionLabel="displayName"
                    optionValue="id"
                    :options="salutationOptions"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.salutation.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.firstName')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.firstName}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.firstName || resolveSelectShopAddressPropertyValue('firstName')) && selectedShopAddress?.firstName !== resolveSelectShopAddressPropertyValue('firstName')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.firstName, customer: resolveSelectShopAddressPropertyValue('firstName') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.firstName.$model"
                    :class="{'p-invalid':v$.firstName.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.firstName, v$.firstName.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.firstName.$model = weclappFormBaseData?.firstName"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.firstName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.lastName')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{selectedShopAddress?.lastName}}<span
                    class="pi pi-exclamation-triangle"
                    v-if="(selectedShopAddress?.lastName || resolveSelectShopAddressPropertyValue('lastName')) && selectedShopAddress?.lastName !== resolveSelectShopAddressPropertyValue('lastName')"
                    v-tooltip.top="$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: selectedShopAddress?.lastName, customer: resolveSelectShopAddressPropertyValue('lastName') })"
                ></span
            ></span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.lastName.$model"
                    :class="{'p-invalid':v$.lastName.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(weclappFormBaseData?.lastName, v$.lastName.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.lastName.$model = weclappFormBaseData?.lastName"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.lastName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.email')}}</div>
        <div class="col">{{shopData?.customer?.email}}</div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.phoneNumber')}}</div>
        <div class="col">{{selectedShopAddress?.phoneNumber}}</div>
    </div>
    <div class="flex justify-content-end w-full" v-if="isEdit">
        <div>
            <i
                class="pi pi-check text-green-600"
                style="cursor: pointer"
                @click="handleSubmit(!v$.$invalid)"
            ></i>
            <i
                class="pi pi-times-circle text-red-600 ml-3"
                style="cursor: pointer"
                @click="resetForm"
            ></i>
        </div>
    </div>
</Panel>
