<div class="grid" v-if="showSearchField || enableChildTreeCheckbox">
    <div class="col w-full">
        <div
            class="flex"
            :class="{'justify-content-between' : showSearchField && enableChildTreeCheckbox, 'justify-content-end' : !(showSearchField && enableChildTreeCheckbox)}"
        >
            <div v-if="enableChildTreeCheckbox" class="flex align-items-center">
                <label class="font-semibold text-sm mr-2"
                    >{{
                    $t("labels.workflows.includeProductsFromChildCategories")
                    }}</label
                >

                <p-checkbox
                    v-model="includeProductsFromChildCategories"
                    @change="triggerEventEmission"
                    binary
                />
            </div>
            <AutoComplete
                v-if="showSearchField"
                v-model="searchTerm"
                :suggestions="filteredResults"
                :placeholder="searchTermPlaceHolder"
                @complete="loadData"
                @item-select="onTermSelected"
                optionLabel="name"
                optionValue="id"
            />
        </div>
    </div>
</div>
<p-tree
    :value="categories"
    :selectionMode="selectionType"
    @nodeExpand="onNodeExpand"
    @nodeSelect="onNodeSelect"
    @nodeUnselect="onNodeUnselect"
    v-model:selectionKeys="selectedCategories"
    :metaKeySelection="false"
    class="text-sm"
    :expandedKeys="expandedKeys"
></p-tree>
