<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid p-fluid">
        <div class="flex col">
            <label class="mt-1">
                {{ $t('labels.ticket.retoure.orderRelated') }}
            </label>
            <span class="ml-2">
                <InputSwitch
                    v-model="v$.orderRelated.$model"
                    :class="{'p-invalid':v$.orderRelated.$invalid && submitted}"
                />
            </span>
        </div>
        <div class="flex col justify-content-end">
            <label class="mt-1">
                {{ $t('labels.ticket.retoure.sendLabelsToRemotePrinters') }}
            </label>
            <span class="ml-2">
                <InputSwitch
                    v-model="v$.sendLabelsToRemotePrinters.$model"
                    :class="{'p-invalid':v$.sendLabelsToRemotePrinters.$invalid && submitted}"
                />
            </span>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="field col" v-if="v$.orderRelated.$model">
            <label
                >{{ $t('labels.ticket.retoure.orderOrInvoiceNumber') }}
            </label>
            <AutoComplete
                v-model="v$.order.$model"
                :suggestions="filteredOrders"
                @complete="searchOrders($event)"
                dropdown
                optionLabel="label"
                forceSelection
                class="w-full"
                :class="{'p-invalid':v$.order.$invalid && submitted}"
            >
                <template #item="slotProps">
                    <b>{{slotProps.item.item.orderNumber}}</b>
                    <span v-if="slotProps.item.invoice" class="ml-3"
                        >[{{$t('labels.ticket.retoure.invoiceNumber')}}:
                        <b>{{slotProps.item.invoice.invoiceNumber}}</b>]</span
                    >
                    <span class="ml-3"
                        >[{{slotProps.item.item.customer.customerNumber}}
                        {{(slotProps.item.item.customer.company ||
                        slotProps.item.item.customer.firstName || '' + ' ' +
                        slotProps.item.item.customer.lastName ||
                        '').trim()}}]</span
                    >
                </template>
            </AutoComplete>
            <small
                v-if="(v$.order.$invalid && submitted) || v$.order.$pending.$response"
                class="p-error"
                >{{v$.order.required.$message.replace('The value',
                $t('labels.ticket.retoure.orderOrInvoiceNumber'))}}</small
            >
        </div>

        <div class="col" v-else>
            <label>{{ $t('labels.ticket.retoure.customer') }} </label>
            <AutoComplete
                v-model="v$.customer.$model"
                :suggestions="filteredCustomers"
                @complete="searchCustomers($event)"
                dropdown
                optionLabel="label"
                optionValue="value"
                forceSelection
                class="w-full"
                :class="{'p-invalid':v$.customer.$invalid && submitted}"
            />
            <small
                v-if="(v$.customer.$invalid && submitted) || v$.customer.$pending.$response"
                class="p-error"
                >{{v$.customer.required.$message}}</small
            >
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col">
            <label>{{ $t('labels.ticket.retoure.warehouse') }} </label>
            <p-dropdown
                filter
                v-model="v$.warehouseId.$model"
                :options="warehouseOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('warehouseId', $event)"
                :class="{'p-invalid':v$.warehouseId.$invalid && submitted}"
                showClear
                class="w-full"
            >
            </p-dropdown>
            <small
                v-if="(v$.warehouseId.$invalid && submitted) || v$.warehouseId.$pending.$response"
                class="p-error"
                >{{v$.warehouseId.required.$message}}</small
            >
        </div>
    </div>

    <p-fieldset class="mb-3" :legend="$t('labels.ticket.referenceDetails')">
        <div class="grid p-fluid">
            <div class="field col">
                <label
                    >{{ $t('labels.ticket.retoure.deliveryNoteDate') }}
                </label>
                <p-calendar
                    :placeholder="$t('labels.ticket.retoure.deliveryNoteDate')"
                    class="w-full"
                    autocomplete="off"
                    dateFormat="dd.mm.yy"
                    v-model="v$.deliveryNoteDate.$model"
                    :class="{'tt-form-input': true, 'p-invalid':v$.deliveryNoteDate.$invalid && submitted}"
                />
                <small
                    v-if="(v$.deliveryNoteDate.$invalid && submitted) || v$.deliveryNoteDate.$pending.$response"
                    class="p-error"
                    >{{v$.deliveryNoteDate.required.$message.replace('Value',
                    $t('labels.ticket.retoure.deliveryNoteDate'))}}</small
                >
            </div>
            <div class="field col">
                <label
                    >{{ $t('labels.ticket.retoure.customerReference') }}
                </label>

                <InputText
                    autocomplete="off"
                    v-model="v$.customerReference.$model"
                    :class="{'p-invalid':v$.customerReference.$invalid && submitted}"
                />
            </div>
        </div>
    </p-fieldset>
    <p-fieldset :legend="$t('labels.ticket.serviceDetails')">
        <div class="grid">
            <div class="col">
                <div class="grid">
                    <div class="col">
                        <label
                            >{{
                            $t('labels.ticket.retoure.internalErrorDescription')
                            }}
                        </label>
                        <div>
                            <p-textarea
                                rows="4"
                                class="w-full"
                                v-model="v$.internalErrorDescription.$model"
                            />
                        </div>
                    </div>
                </div>

                <div class="grid">
                    <div class="col">
                        <label
                            >{{
                            $t('labels.ticket.retoure.customerErrorDescription')
                            }}
                        </label>
                        <div>
                            <p-textarea
                                rows="4"
                                class="w-full"
                                v-model="v$.customerErrorDescription.$model"
                            />
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col flex justify-content-between">
                        <div>
                            <label>
                                {{ $t('labels.ticket.retoure.isChargeable') }}
                            </label>
                            <p-checkbox
                                class="ml-2"
                                v-model="v$.isChargeable.$model"
                                binary
                            />
                        </div>
                        <div>
                            <label>
                                {{ $t('labels.ticket.retoure.serviceReceipt') }}
                            </label>

                            <p-checkbox
                                class="ml-2"
                                v-model="v$.serviceReceipt.$model"
                                binary
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col" v-if="tinyTemplates !== null">
                <label>{{ $t('labels.ticket.retoure.returnReasons') }} </label>
                <tiny
                    :tinymceScriptSrc="tinyUrl"
                    v-model="v$.returnReasons.$model"
                    :init="{
                    height: 200,
                    menubar: false,
                    format: 'text',
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste template',
                    toolbar: 'undo redo | styleselect | template | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true,
                    templates : tinyTemplates,
                    template_replace_values: {
                        'ticketOwner': ticketDetails?.customer?.company ||
                                (ticketDetails?.customer?.firstName +
                            ' ' +
                            ticketDetails?.customer?.lastName),
                        'ticketNumber': ticketDetails.ticketNumber,
                    },
                }"
                ></tiny>
            </div>
        </div>
    </p-fieldset>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.cancel')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" v-text="$t('buttons.next')" type="submit">
        </p-button>
    </div>
</form>
