import {computed, onMounted, ref} from 'vue';
import {getAll} from '@/services/products';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Column from 'primevue/column';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import TieredMenu from 'primevue/tieredmenu';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';
import BulkActionsMenu from '@/components/bulk-actions/bulk-actions-menu.vue';
import SalesChannelSwitcher from '@/components/sales-channel-switcher/sales-channel-switcher.vue';

import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import store from '@/store';
import {
    stripTagsAndTruncate,
    isValueEqualCaseInsensitive,
    getShopwareUrl
} from '@/utils/helpers';

export default {
    components: {
        DataTable,
        InputText,
        InputSwitch,
        Dropdown,
        'p-button': Button,
        'p-multiselect': MultiSelect,
        FilterMenu,
        Column,
        Calendar,
        Badge,
        BulkActionsMenu,
        loading: LoadingPlugin,
        PrimeDialog: Dialog,
        PrimePanel: Panel,
        TriStateCheckbox,
        TieredMenu,
        Tag,
        SalesChannelSwitcher
    },
    setup() {
        onMounted(async () => {
            if (localStorage.getItem('products-webshop-view-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value
            };

            loadLazyData();
        });

        const tableState = ref(null);
        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);
        const toast = useToast();

        const dt = ref();
        const lazyParams: any = ref({});
        const expandedRows = ref([]);

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(
                tableState.value?.filters || {}
            ).filter((e) => e !== 'view');

            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                localStorage.removeItem('products-webshop-view-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filtersDefinition);
            } else {
                delete event.filters?.view;
                filters.value = Object.assign(
                    {},
                    filtersDefinition,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event?.rows || 10,
                sortField: event?.sortField || 'articleNumber',
                sortOrder: event?.sortOrder || 1,
                filters: filters.value
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filtersDefinition);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            const filtered = Object.keys(savedFilters)
                .filter((key) => filtersDefinitionKeys.includes(key))
                .reduce((obj: any, key) => {
                    obj[key as keyof typeof obj] = savedFilters[key];
                    return obj;
                }, {});

            filters.value = Object.assign({}, filtersDefinition, {
                ...filtered
            });
            onFilter();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.columns = ['articleNumber', 'name', 'ean'];
            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},

            ean: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerPartNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },

            activeInShopware: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            shopImageExists: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdd'
                    );
                case 'ttl':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdl'
                    );
                case 'vdd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttd'
                    );
                default:
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttl'
                    );
            }
        });

        const onCloseBulkBadgesCategoriesDialog = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
            loadLazyData();
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const showCommentsColumn = computed(() => {
            return (
                products.value &&
                products.value.some(
                    (item: any) => item.comments && item.comments.length > 0
                )
            );
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const resolvedShopwareUrl = (
            shopwareUrl: string,
            shopwareData: {name: string; seoUrl: string | null},
            threeLetterId: string
        ) => {
            return (
                shopwareUrl +
                (shopwareData.seoUrl !== null
                    ? shopwareData.seoUrl
                    : getShopwareUrl(shopwareData.name, threeLetterId) +
                      (threeLetterId === 'vct' ? '' : '.html'))
            );
        };

        const historyFilters = computed(() => {
            return JSON.stringify(lazyParams.value);
        });

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        return {
            loading,
            loadLazyData,
            totalRecords,
            products,
            filters,
            selectedProducts,
            mergedSelection,
            expandedRows,
            dt,
            matchModesNumeric,
            matchModeWithEmptyFilter: [
                {label: 'Starts with', value: FilterMatchMode.STARTS_WITH},
                {label: 'Contains', value: FilterMatchMode.CONTAINS},
                {label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS},
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {label: 'Equals', value: FilterMatchMode.EQUALS},
                {
                    label: 'Not equals',
                    value: FilterMatchMode.NOT_EQUALS
                },
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {
                    label: 'Is Empty',
                    value: 'isEmpty'
                },
                {
                    label: 'Is Not empty',
                    value: 'isNotEmpty'
                }
            ],
            onFilter,
            onPage,
            onSort,
            onStateRestore,
            expandRow,
            isEmptyKeydownCalldown: (model: any, callb: any) => {
                if (model.value.trim() === '=') {
                    model.matchMode = 'isEmpty';
                } else if (model.value.trim() === '*') {
                    model.matchMode = 'isNotEmpty';
                }
                callb();
            },
            editPermissionAvailable,
            stripTagsAndTruncate,
            onCloseBulkBadgesCategoriesDialog,
            showCommentsColumn,
            resolvedShopwareUrl,
            isValueEqualCaseInsensitive,
            historyFilters,
            applyFilters,
            clearFilters,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            resolvedPlatform,
            secondPlatform
        };
    }
};
