import {ref, onMounted, computed} from 'vue';
import DataTable from 'primevue/datatable';
import Toolbar from 'primevue/toolbar';
import SplitButton from 'primevue/splitbutton';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getAll} from '@/services/purchase-orders';
import {i18n} from '@/utils/i18n';
import countries from 'i18n-iso-countries';
import {DateTime} from 'luxon';
import {getProfile} from '@/services/auth';
import store from '@/store';
import {getAll as getAllProducts} from '@/services/products';
import * as papa from 'papaparse';
import FileSaver from 'file-saver';

export default {
    components: {
        DataTable,
        Toolbar,
        SplitButton,
        Column,
        InputText,
        InputNumber,
        Dropdown,
        Panel,
        Calendar,
        loading: LoadingPlugin,
        'p-checkbox': Checkbox
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('purchase-order-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'createdDate',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const toast = useToast();

        const purchaseOrders = ref();

        const filterDefintions: any = {
            purchaseOrderNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            supplierCombo: {value: null, matchMode: FilterMatchMode.CONTAINS},
            recipient: {value: null, matchMode: FilterMatchMode.CONTAINS},
            status: {value: null, matchMode: FilterMatchMode.EQUALS},
            purchaseOrderType: {value: null, matchMode: FilterMatchMode.EQUALS},
            warehouse: {value: null, matchMode: FilterMatchMode.CONTAINS},
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    purchaseOrders.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdAtFormatted: DateTime.fromMillis(obj.createdDate)
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED),
                        supplierCombo:
                            obj.supplier?.supplierNumber +
                            ' ' +
                            obj.supplier?.name
                    }));
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const outputRecipientInGrid = (data: any) => {
            let output = '';

            if (data.deliveryAddress?.company) {
                output += ` ${data.deliveryAddress.company}`;
            } else if (
                data.deliveryAddress?.lastName &&
                data.deliveryAddress?.firstName
            ) {
                output += ` ${data.deliveryAddress.lastName}, ${data.deliveryAddress.firstName}`;
            }

            return output;
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'] || getProfile();
            return (
                user?.permissions?.indexOf('weclapp-purchase-orders-edit') !==
                -1
            );
        });

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                ) ||
                tableState.value?.filters?.shipmentType?.matchMode ===
                    FilterMatchMode.CONTAINS ||
                tableState.value?.filters?.status?.matchMode ===
                    FilterMatchMode.CONTAINS
            ) {
                localStorage.removeItem('purchase-order-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            } else {
                filters.value = Object.assign(
                    {},
                    filterDefintions,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'createdDate',
                sortOrder: event.sortOrder || -1,
                filters: filters.value
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filterDefintions);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const savedFilterKeys = Object.keys(savedFilters || {}).filter(
                (e) => e !== 'view'
            );

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                savedFilterKeys.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                filters.value = Object.assign({}, filterDefintions, {
                    ...savedFilters
                });
                onFilter();
            }
        };

        const downloadPurchaseOrderDraft = async (purchaseOrder: any) => {
            const products: any = [];
            (purchaseOrder.purchaseOrderItems || []).forEach(
                (orderItem: any) => {
                    products.push(orderItem.articleNumber);
                }
            );

            loading.value = true;

            try {
                const loadedProducts = await getAllProducts({
                    first: 0,
                    rows: products.length,
                    columns: ['id', 'articleNumber', 'name'],
                    filters: {
                        articleNumber: {
                            value: products,
                            matchMode: FilterMatchMode.IN
                        }
                    }
                });

                const csvData: any = [];

                csvData.push([
                    'KdNr',
                    'Datum',
                    'Uhrzeit',
                    'FilialNr',
                    'Lieferkenzeichen',
                    'Kopftext',
                    'ArtNr',
                    'Menge'
                ]);

                (purchaseOrder.purchaseOrderItems || []).forEach(
                    (orderItem: any) => {
                        if (
                            (loadedProducts.data?.items || []).some(
                                (pr: any) => {
                                    return (
                                        pr.articleNumber ===
                                            orderItem.articleNumber &&
                                        (pr.articleSupplySources || []).some(
                                            (source: any) =>
                                                source.supplierNumber ===
                                                purchaseOrder.supplier
                                                    ?.supplierNumber
                                        )
                                    );
                                }
                            )
                        ) {
                            csvData.push([
                                '53040-01',
                                DateTime.fromJSDate(new Date())
                                    .setLocale(i18n.global.locale)
                                    .setZone(
                                        process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                    )
                                    .toLocaleString(DateTime.DATE_SHORT),
                                DateTime.fromJSDate(new Date())
                                    .setLocale(i18n.global.locale)
                                    .setZone(
                                        process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                    )
                                    .toLocaleString(DateTime.TIME_24_SIMPLE),
                                0,
                                'V',
                                purchaseOrder.purchaseOrderNumber,
                                (loadedProducts.data?.items || [])
                                    .find(
                                        (pr: any) =>
                                            pr.articleNumber ===
                                            orderItem.articleNumber
                                    )
                                    .articleSupplySources.find(
                                        (source: any) =>
                                            source.supplierNumber ===
                                            purchaseOrder.supplier
                                                ?.supplierNumber
                                    ).articleNumber,
                                orderItem.quantity
                            ]);
                        }
                    }
                );

                FileSaver.saveAs(
                    new Blob(
                        [
                            papa.unparse(csvData, {
                                delimiter: ';'
                            })
                        ],
                        {
                            type: 'text/csv;charset=utf-8'
                        }
                    ),
                    'Ringfoto-Bestellvorlage-' +
                        purchaseOrder.purchaseOrderNumber +
                        '-' +
                        DateTime.fromJSDate(new Date()).toISODate() +
                        '.csv'
                );
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                loading.value = false;
            }
        };

        return {
            purchaseOrders,
            dt,
            totalRecords,
            loading,
            lazyParams,
            filters,
            onPage,
            onFilter,
            onSort,
            outputRecipientInGrid,
            matchModesNumeric,
            clearFilters,
            applyFilters,
            countries,
            locale: i18n.global.locale,
            onStateRestore,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: 'EUR'
            }),
            editPermissionAvailable,
            downloadPurchaseOrderDraft
        };
    }
};
