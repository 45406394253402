import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAllAddresses = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/manufacturer-addresses');
};

export const getAllResponsiblePersons = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/manufacturer-responsible-persons');
};

export const putManufacturerAddress = (
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/manufacturer-addresses', {...payload});
};

export const putManufacturerResponsiblePerson = (
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/manufacturer-responsible-persons', {
        ...payload
    });
};
