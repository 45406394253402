import {computed, onMounted, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import Dropdown from 'primevue/dropdown';
import {getAll} from '@/services/metadata';
import MultiSelect from 'primevue/multiselect';
import {addNew, editExisting} from '@/services/print-configurations';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-input': InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        'p-multiselect': MultiSelect,
        loading: LoadingPlugin
    },
    props: {
        displayDialog: Boolean,
        existingConfiguration: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const existingConfiguration = ref(null);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);
        const osInstances = ref([]);
        const userOptions = ref([]);
        const printerSizeOptions = ref([]);
        const customAttributeDefinitions = ref([]);
        const loading = ref(false);

        const eventOptions = [
            {
                id: 'ups-label-created',
                entityName: 'shipment',
                label: i18n.global.t('events.ups-label-created')
            },
            {
                id: 'delivery-note-created-on-pickup',
                entityName: 'shipment',
                label: i18n.global.t('events.delivery-note-created-on-pickup')
            },
            {
                id: 'dangerous-goods-announced',
                entityName: 'shipment',
                label: i18n.global.t('events.dangerous-goods-announced')
            },
            {
                id: 'ticket-article-label-created',
                entityName: 'ticket',
                label: i18n.global.t('events.ticket-article-label-created')
            },
            {
                id: 'ticket-service-ticket-label-created',
                entityName: 'ticket',
                label: i18n.global.t(
                    'events.ticket-service-ticket-label-created'
                )
            }
        ];

        const state = ref({
            hardwareId: null,
            event: null,
            printer: null,
            printerSize: null,
            author: null,
            entityName: null,
            attributeName: null,
            attributeValue: null
        });

        onMounted(() => {
            getAll(
                [
                    'weclappOS',
                    'user',
                    'customAttributeDefinition',
                    'paperSize',
                    'shipmentMethod'
                ],
                false
            ).then((data: any) => {
                osInstances.value = data.data['weclappOS'];
                userOptions.value = data.data['user'];
                customAttributeDefinitions.value =
                    Object.values(
                        data.data['customAttributeDefinition'] || {}
                    ) || [];
                printerSizeOptions.value = (data.data['paperSize'] || []).map(
                    (option: string) => {
                        return {
                            id: option
                        };
                    }
                );
            });
        });

        const rules = {
            hardwareId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            event: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            printer: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            printerSize: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            author: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            entityName: {},
            attributeName: {},
            attributeValue: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;
            existingConfiguration.value = args.existingConfiguration;

            if (existingConfiguration.value) {
                Object.keys(existingConfiguration.value).forEach(
                    (configurationKey: string) => {
                        if (
                            Object.keys(state.value).includes(configurationKey)
                        ) {
                            state.value[
                                configurationKey as keyof typeof state.value
                            ] = existingConfiguration.value[configurationKey];
                        }
                    }
                );

                state.value.printer =
                    existingConfiguration.value.printerName || null;
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                hardwareId: null,
                event: null,
                printer: null,
                printerSize: null,
                author: null,
                entityName: null,
                attributeName: null,
                attributeValue: null
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                if (existingConfiguration.value) {
                    await editExisting(
                        existingConfiguration.value.id,
                        state.value
                    );
                } else {
                    await addNew(state.value);
                }
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    hardwareId: null,
                    event: null,
                    printer: null,
                    printerSize: null,
                    author: null,
                    entityName: null,
                    attributeName: null,
                    attributeValue: null
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            if ('event' === fieldName) {
                temp['entityName'] = eventOptions.find((item: any) => {
                    return item.id === event.value;
                }).entityName;
            }

            Object.assign(state.value, temp);
        };

        const printerOptions = computed(() => {
            if (!state.value.hardwareId) {
                return [];
            }

            return osInstances.value
                .find((item: any) => {
                    return item.name === state.value.hardwareId;
                })
                .printerNames.map((option: string) => {
                    return {
                        id: option
                    };
                });
        });

        const attributeNameOptions = computed(() => {
            if (!state.value.event) {
                return [];
            }

            return customAttributeDefinitions.value.filter((item: any) => {
                return (
                    item.active &&
                    item.attributeType.toUpperCase() === 'STRING' &&
                    item.entities.indexOf(state.value.entityName) !== -1
                );
            });
        });

        return {
            showDialog,
            loading,
            osInstances,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            printerOptions,
            userOptions,
            eventOptions,
            attributeNameOptions,
            printerSizeOptions,
            existingConfiguration
        };
    }
};
