<h6>{{ $t("labels.columnMapping") }}</h6>
<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <DataTable
        class="text-sm"
        stripedRows
        :rows="items.length"
        dataKey="id"
        :value="items"
        responsiveLayout="scroll"
    >
        <Column :header="$t('labels.weclapp')">
            <template #body="{data}">
                <div :class="{'font-bold': data.required}">
                    {{ data.header.charAt(0).toUpperCase() +
                    data.header.slice(1) }}
                    <template v-if="data.required">*</template>
                </div>
            </template>
        </Column>
        <Column :header="$t('labels.yourFile')">
            <template #body="{data}">
                <p-dropdown
                    v-model="state.itemsToSubmit.find((item) => item.header.trim() === data.header.trim()).mapped"
                    :class="{'p-invalid':submitted && v$.itemsToSubmit.$each.$response.$data[state.itemsToSubmit.findIndex((item) => item.header === data.header)].mapped.$invalid}"
                    :options="options"
                    filter
                    class="w-full"
                    showClear
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.itemsToSubmit.$each.$response.$errors[state.itemsToSubmit.findIndex((item) => item.header === data.header)].mapped"
                    class="p-error mt-1"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </template>
        </Column>
        <Column :header="$t('labels.preview')">
            <template #body="{data}">
                {{ getRowExample(data.header) }}
            </template>
        </Column>
    </DataTable>
    <div class="mt-3 flex justify-content-end">
        <p-button severity="success" type="submit">
            {{ $t("buttons.processFile") }}
        </p-button>
    </div>
</form>
