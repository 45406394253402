import mutations from './mutations';
import actions from './actions';
import getters from './getters';
const importModule: {
    namespaced: boolean;
    state: {
        shopOrdersInTeltec: boolean;
        shopOrdersInVideodata: boolean;
    };
    mutations: any;
    actions: any;
    getters: any;
} = {
    namespaced: true,
    state: {
        shopOrdersInTeltec: true,
        shopOrdersInVideodata: false
    },
    mutations,
    actions,
    getters
};

export default importModule;
