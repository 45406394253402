import {getAll} from '@/services/product-badges';
import Button from 'primevue/button';
import {computed, ref, toRefs, watch} from 'vue';
import {useToast} from 'vue-toastification';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {updateBadges} from '@/services/products';
import {i18n} from '@/utils/i18n';
import store from '@/store';
import InputText from 'primevue/inputtext';
import {FilterMatchMode} from 'primevue/api';

export default {
    emits: ['reload-product'],
    components: {
        DataTable,
        Column,
        'p-checkbox': Checkbox,
        'p-button': Button,
        InputText
    },
    props: {
        shopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const toast = useToast();
        const {platform} = toRefs(props);
        const badges = ref([]);
        const buttonsDisabled = ref(false);
        let initialBadges: Array<string> = [];
        const productBadges = ref([]);

        const filters = ref({
            name: {value: null, matchMode: FilterMatchMode.CONTAINS}
        });

        watch(props, () => {
            initialBadges = props.shopwareDetails?.badges;
            productBadges.value = props.shopwareDetails?.badges;
            buttonsDisabled.value = false;
            loadLazyData();
        });

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const loadLazyData = () => {
            getAll(platform.value)
                .then((data) => {
                    badges.value = data.data.sort((a: any, b: any) => {
                        if (
                            ['vdd', 'vdl'].indexOf(
                                importPlatform.value?.threeLetterId
                            ) === -1
                        ) {
                            return a.name.localeCompare(b.name);
                        }
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') === -1
                        )
                            return 1;

                        if (
                            a.name.indexOf('€') === -1 &&
                            b.name.indexOf('€') !== -1
                        )
                            return -1;
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') !== -1 &&
                            parseInt(a.name.match(/\d/g).join(''), 10) !==
                                parseInt(b.name.match(/\d/g).join(''), 10)
                        ) {
                            return parseInt(a.name.match(/\d/g).join(''), 10) >
                                parseInt(b.name.match(/\d/g).join(''), 10)
                                ? 1
                                : -1;
                        }

                        return a.name.localeCompare(b.name);
                    });
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const saveChanges = () => {
            buttonsDisabled.value = true;
            updateBadges(
                props.shopwareDetails?.productNumber,
                productBadges.value,
                platform.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    buttonsDisabled.value = false;
                });
        };

        const discardChanges = () => {
            productBadges.value = initialBadges;
        };

        const buttonsHidden = computed(() => {
            return (
                productBadges.value &&
                productBadges.value.sort().toString() ===
                    initialBadges.sort().toString()
            );
        });

        return {
            badges,
            productBadges,
            discardChanges,
            saveChanges,
            buttonsDisabled,
            buttonsHidden,
            filters
        };
    }
};
